import Cookies from 'js-cookie';

import { JWT_NAME } from '@/constants/cookieName';

export function getAuthorizationHeader() {
  const token = getAccessToken();
  if (!token) return undefined;
  return `Bearer ${token}`;
}

export function getAccessToken() {
  return Cookies.get(JWT_NAME);
}
