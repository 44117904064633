import styled from "@emotion/styled";
import React from "react";

const Wrap = styled.div`
  position: relative;
`;
const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  color: #f00000;
  position: absolute;
  bottom: -4px;
  left: 0px;
  width: 100%;
  transform: translateY(100%);
`;

type ValidationContainerProps = {
  wrapperStyles?: React.CSSProperties;
  errorStyles?: React.CSSProperties;
  children: React.ReactElement[] | React.ReactElement;
  error: string | undefined | null;
};

export const ValidationContainer: React.FC<ValidationContainerProps> = ({
  children,
  wrapperStyles,
  error,
  errorStyles,
}: ValidationContainerProps) => {
  return (
    <Wrap style={wrapperStyles}>
      {children}
      {error && (
        <ErrorMessage data-cy="error-message" style={errorStyles}>
          {error}
        </ErrorMessage>
      )}
    </Wrap>
  );
};
