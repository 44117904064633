import { ReactElement, useState, useMemo, useRef, useEffect } from "react";
import styled from "@emotion/styled";

import { Body } from "./Body";
import { BottomSheetProps } from "./common/type";
import { BottomSheetContainer as Container } from "./Container";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { HALF_HEIGHT, REMAIN_HEIGHT } from "./common/constants";
import { subtractBottomSheetHeight } from "./common/utils";
import { useOutsideClick } from "../../../utils/hooks/useOutsideClick";

function BottomSheet({
  isOpen,
  onClose,
  closeOnOutsideClick,
  zIndex,
  defaultHeight,
  makeHeightFitContent = false,
  extensionbar = true,
  underlay = true,
  isExpand = false,
  ...props
}: BottomSheetProps): ReactElement {
  const hasTitle = "title" in props;
  const hasContents = "contents" in props;
  const hasButtons = "buttons" in props;

  const subtractHeight = useMemo(
    () =>
      subtractBottomSheetHeight({
        hasButtons,
        hasExtensionBar: extensionbar ?? true,
        remainHeight: REMAIN_HEIGHT,
      }),
    []
  );

  const getDefaultBottomSheetHeight = useMemo(() => {
    return defaultHeight
      ? defaultHeight - subtractHeight + "px"
      : makeHeightFitContent
      ? "auto"
      : HALF_HEIGHT;
  }, []);

  const bottomSheetHeightState = useState(getDefaultBottomSheetHeight);
  const [bottomSheetHeight, setBottomSheetHeight] = bottomSheetHeightState;

  const containerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = () => {
    if (closeOnOutsideClick) {
      setBottomSheetHeight(getDefaultBottomSheetHeight);
      onClose();
    }
  };

  useEffect(() => {
    if (isExpand) {
      setBottomSheetHeight("100vh");
    }
  }, [isExpand]);

  useOutsideClick([containerRef], handleOutsideClick);

  const header = hasTitle && <Header> {props.title}</Header>;
  const contents = hasContents && (
    <Body hasBottom={hasButtons} height={bottomSheetHeight}>
      {props.contents}
    </Body>
  );
  const buttons = hasButtons && <Footer buttons={props.buttons} />;

  return (
    <Container
      isOpen={isOpen}
      extensionbar={extensionbar}
      hasTitle={hasTitle}
      ref={containerRef}
      makeHeightFitContent={makeHeightFitContent}
      bottomSheetHeightState={bottomSheetHeightState}
      defaultHeight={getDefaultBottomSheetHeight}
      underlay={underlay}
      zIndex={zIndex}
      onClose={onClose}
    >
      {header}
      {contents}
      {buttons}
    </Container>
  );
}

const StyledBottomSheet = styled(BottomSheet)``;

export { StyledBottomSheet as BottomSheet };
