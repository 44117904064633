import axios, { AxiosError } from 'axios';

import { getMemberyoApiUrl } from '@/data/server-state/api-clients/utils/get-memberyo-api-url';
import { ResponseDataError } from '@/data/server-state/types/error';
import { getAuthorizationHeader } from '@/utils/cookieHandler';
import { restartIdleTimer } from '@/utils/hooks/idleSession/idleSession';

const getBaseURL = () => {
  const isWindowAvailable = typeof window !== 'undefined';
  const memberyoApiUrl = isWindowAvailable
    ? getMemberyoApiUrl(window.origin)
    : process.env.NEXT_PUBLIC_MEMBERYO_API_URL;

  return memberyoApiUrl;
};

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: getBaseURL(),
    timeout: 30 * 1000,
  });

  instance.interceptors.request.use(
    (config) => {
      restartIdleTimer();
      config.headers.Authorization = getAuthorizationHeader();
      config.baseURL = getBaseURL();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError<ResponseDataError<unknown>>) => {
      return Promise.reject(error?.response || error);
    }
  );

  return instance;
};
const memberyoApiClient = createAxiosInstance();

export default memberyoApiClient;
