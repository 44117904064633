import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PreviousRoute = {
  previousRoute: string;
};

const initialState: PreviousRoute = {
  previousRoute: '',
};

export const routeSlice = createSlice({
  name: 'routeSlice',
  initialState,
  reducers: {
    setPreviousRoute: (state, action: PayloadAction<string>) => {
      state.previousRoute = action.payload;
    },
  },
});

export const routeReducer = routeSlice.reducer;

export const { setPreviousRoute } = routeSlice.actions;
