import { keys } from 'ramda';

import {
  BLOCK_MANAGEMENT,
  KISA_MANAGEMENT,
  MEMBER_MANAGEMENT_AUTHENTICATION_RESET,
  MEMBER_MANAGEMENT_INQUIRY,
} from '@/constants/routePath';

export interface MenuType {
  name: string;
  enabled: boolean;
  path: string;
}

export type MenuListType = MenuType & { sub?: MenuType[] };

const getFirstPathHasPermission = (menuList: MenuType[] = []): string => {
  const getMenuEnabled = menuList.find((menuItem) => menuItem.enabled);
  if (getMenuEnabled?.path) {
    return getMenuEnabled.path;
  }
  return '';
};

export const getMenu = (data?: Record<string, boolean>): MenuListType[] => {
  if (!data) return [];
  const menu: MenuListType[] = [
    {
      name: '회원 관리',
      enabled: data['회원 관리'],
      path: '',
      sub: [
        { name: '회원 조회', enabled: data['회원 조회'], path: MEMBER_MANAGEMENT_INQUIRY },
        {
          name: '휴대폰 인증 초기화',
          enabled: data['휴대폰 인증 초기화'],
          path: MEMBER_MANAGEMENT_AUTHENTICATION_RESET,
        },
      ],
    },
    {
      name: '블랙리스트 관리',
      enabled: data['블랙리스트 관리'],
      sub: [],
      path: BLOCK_MANAGEMENT,
    },
    {
      name: '탈퇴 요청 관리',
      enabled: data['탈퇴 요청 관리'],
      path: '',
      sub: [
        {
          name: '탈퇴 요청 처리',
          enabled: data['탈퇴 요청 조회'],
          path: KISA_MANAGEMENT,
        },
      ],
    },
  ];

  for (const menuItem of menu) {
    if (menuItem.path) {
      continue;
    }
    menuItem.path = getFirstPathHasPermission(menuItem?.sub);
  }
  return menu;
};

const URLS = Object.freeze({
  '/member-management/inquiry': '회원 조회',
  '/member-management/authentication-reset': '휴대폰 인증 초기화',
  '/block-management': '블랙리스트 관리',
  '/kisa-management': '탈퇴 요청 처리',
});

export type UrlKeys = keyof typeof URLS;

export const getPathByMenuName = (menu: string) => keys(URLS).find((path) => URLS[path] === menu);
export const getMenuByPath = (path: string) => URLS[path as UrlKeys] ?? '알 수 없는 메뉴';
