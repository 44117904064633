import { FormControlLabel, FormGroup } from "@mui/material";
import { CheckBox, CheckBoxProps } from "../atoms/checkbox/Checkbox";

export const CheckBoxWithLabel = (props: CheckBoxProps) => {
  return (
    <FormGroup>
      <FormControlLabel control={<CheckBox {...props} />} label="Label" />
    </FormGroup>
  );
};
