import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import React, { ReactElement } from "react";

const LoadingBox = styled.div`
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const LoadingBackDropStyle = styled.div<LoadingBackDropProps>((props) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.3)",
  zIndex: 1501,
  ...props.styles,
}));

export function LoadingBasic() {
  return (
    <LoadingBox>
      <CircularProgress color="inherit" />
    </LoadingBox>
  );
}

type LoadingBackDropProps = {
  styles?: React.CSSProperties;
  children: ReactElement[] | ReactElement;
};
export function LoadingBackDrop({ children, styles }: LoadingBackDropProps) {
  return (
    <LoadingBackDropStyle styles={styles}>{children}</LoadingBackDropStyle>
  );
}
