import { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

const common = `@media screen`;

export const numberToMinWidthQuery = (value?: number): string | undefined =>
  isFinite(Number(value)) ? `(min-width: ${Number(value)}px)` : undefined;

export const numberToMaxWidthQuery = (value?: number): string | undefined =>
  isFinite(Number(value)) ? `(max-width: ${Number(value) - 1}px)` : undefined;

const stringifyQuery = (queries: (string | undefined)[]) => {
  return queries.filter(Boolean).join(' and ');
};

function combineStyleWithMediaQuery(mediaQuery: string, style: SerializedStyles): SerializedStyles {
  return css`
    ${mediaQuery} {
      ${style}
    }
  `;
}

export const createCSSWithExactMediaQuery = (small?: number, large?: number) => {
  const mediaQuery = stringifyQuery([
    common,
    numberToMinWidthQuery(small),
    numberToMaxWidthQuery(large),
  ]);

  return (style: SerializedStyles) => {
    return combineStyleWithMediaQuery(mediaQuery, style);
  };
};

export const createCSSWithGreatThenMediaQuery = (min: number) => {
  const mediaQuery = stringifyQuery([common, numberToMinWidthQuery(min)]);

  return (style: SerializedStyles) => {
    return combineStyleWithMediaQuery(mediaQuery, style);
  };
};

export const createCSSWithLessThenMediaQuery = (max: number) => {
  const mediaQuery = stringifyQuery([common, numberToMaxWidthQuery(max)]);

  return (style: SerializedStyles) => {
    return combineStyleWithMediaQuery(mediaQuery, style);
  };
};
