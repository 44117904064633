import styled from "@emotion/styled";

interface HrProps {
  margin?: string;
}

const StyledHr = styled.hr`
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
`;

export const Hr = ({ margin }: HrProps) => {
  return (
    <StyledHr
      style={{
        margin: margin || "10px 0",
      }}
    />
  );
};
