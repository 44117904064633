import { RouteGuard } from '@/components/common-components/auth/RouteGuard';
import { Toast } from '@/components/common-components/molecules/Toast';
import { usePreviousRoute } from '@/data/client-state/hooks/usePreviousRoute';
import { AppPropsWithLayout } from '@/pages/_app';
import { useIdleSession } from '@/utils/hooks/idleSession/useIdleSession';

export function AppContainer({ Component, pageProps }: AppPropsWithLayout) {
  usePreviousRoute();
  useIdleSession();
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <RouteGuard>
      {getLayout(<Component {...pageProps} />)}
      <Toast />
    </RouteGuard>
  );
}
