import "react-datepicker/dist/react-datepicker.css";
import { Stack } from "@mui/material";
import { EditCalendarRounded } from "@mui/icons-material";
import { ComponentProps, useState } from "react";
import BaseDatePicker, { setDefaultLocale } from "react-datepicker";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { FormHelperText } from "../TextField";

const StyleDatePicker = styled.div`
  width: 140px;
  z-index: 20;
  input {
    padding: 0px 24px 0px 4px;
    border: 1px solid #ccc;
    height: 24px;
    width: 100%;
    &:focus {
      border-color: #595959;
    }
  }
  svg {
    position: absolute;
    top: 2px;
    right: 5px;
  }
`;

setDefaultLocale("ko");

type BaseDatePickerProps = ComponentProps<typeof BaseDatePicker>;
type PrivateProps = "value";
type DatePickerProps = Omit<BaseDatePickerProps, PrivateProps> & {
  date: Date | null | undefined;
  error?: string;
};

const isValidDate = (dateString: string) => {
  const dateRegex = /(\d{4}.)(\d{1,2}.)(\d{1,2})/;

  if (!dateRegex.test(dateString)) {
    return false;
  }

  const date = new Date(dateString.split(".").join("-"));

  return date instanceof Date && !isNaN(date.getTime());
};

export const DatePicker = (props: DatePickerProps) => {
  const { date, error, onChange } = props;

  const [inputValue, setInputValue] = useState(
    date ? date.toLocaleDateString("ko-KR") : ""
  );

  // TODO: format date
  const displayInputValue = inputValue ?? "";

  const handleDateChange = (date: Date) => {
    onChange(date, undefined);
  };

  const handleDateSelect = (date: Date | null) => {
    setInputValue(date ? date.toLocaleDateString("ko-KR") : "");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value.replace(/[^0-9.]/g, "").slice(0, 10));
  };

  const updateDateIfInputValueIsDate = (inputValue: string) => {
    if (isValidDate(inputValue)) {
      const nextDate = new Date(inputValue);

      onChange(nextDate, undefined);
    }
  };

  const handleInvalidDateInput = () => {
    if (!isValidDate(inputValue)) {
      handleDateSelect(date ?? null);
    }
  };

  useEffect(() => {
    updateDateIfInputValueIsDate(inputValue);
  }, [inputValue]);

  return (
    <StyleDatePicker>
      <BaseDatePicker
        selected={date}
        dateFormat={"yyyy.MM.dd"}
        customInput={
          <div style={{ position: "relative" }}>
            <input
              value={displayInputValue}
              type="text"
              onChange={handleInputChange}
              onBlur={handleInvalidDateInput}
            />
            <EditCalendarRounded
              sx={{
                position: "absolute",
                right: "20px",
              }}
            ></EditCalendarRounded>
          </div>
        }
        {...props}
        onChange={handleDateChange}
        onSelect={handleDateSelect}
      />
      <FormHelperText error={error}>{error}</FormHelperText>
    </StyleDatePicker>
  );
};
