import {
  Children,
  ComponentPropsWithoutRef,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import {
  TableBlock,
  TableList,
  TableListCellTitle,
  TableRow,
} from "../../organisms/table/BaseTableParts";
import styled from "@emotion/styled";

export interface TableProps extends ComponentPropsWithoutRef<"div"> {
  columns: string[];
  scroll?: boolean;
  children?: ReactNode;
  sizes?: string[];
  footer?: ReactNode;
  renderEmpty?: ReactNode;
  col_2?: boolean;
}

type BaseTableRefHandler = {
  scrollToTopOfTheTable: () => void;
};

export const BaseTable = forwardRef<BaseTableRefHandler, TableProps>(
  (
    {
      columns,
      scroll = false,
      children,
      sizes,
      footer,
      col_2,
      renderEmpty,
      className,
      ...containerDivProps
    },
    parentRef
  ) => {
    const tableRef = useRef<HTMLDivElement>(null);

    const isLayoutFixed = !!sizes?.length;
    const numberOfChild = Children.count(children);

    useImperativeHandle(
      parentRef,
      () => {
        return {
          scrollToTopOfTheTable() {
            tableRef.current?.scrollTo(0, 0);
          },
        };
      },
      []
    );

    return (
      <TableBlock
        ref={tableRef}
        scroll={scroll}
        col_2={col_2}
        className={className}
        {...containerDivProps}
      >
        <TableList
          center
          fixed={isLayoutFixed}
          style={{ position: "relative" }}
        >
          <colgroup>
            {columns.map((col, index) => (
              <col key={col} width={sizes?.[index]} />
            ))}
          </colgroup>
          <thead
            style={{
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <TableRow data-cy="table-title">
              {columns.map((column) => (
                <TableListCellTitle key={column}>{column}</TableListCellTitle>
              ))}
            </TableRow>
          </thead>
          <tbody data-cy="table-body">{children}</tbody>
        </TableList>
        {!numberOfChild ? renderEmpty : null}
        {footer}
      </TableBlock>
    );
  }
);
