import { Box } from '@mui/material';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingBasic } from 'ui';

import {
  getMenu,
  MenuListType,
} from '@/components/common-components/layouts/side-navigation-bar/menu-url-mapper';
import { createMenuObject } from '@/components/common-components/layouts/side-navigation-bar/SideNavigationBarContainer';
import { USER_ROLE } from '@/constants/cookieName';
import {
  ADMIN_PORTAL_LOGOUT_URL,
  FORBIDDEN_PAGE,
  HOME_PATH,
  PUBLIC_PATHS,
} from '@/constants/routePath';
import { getSystemMenus } from '@/data/server-state/api-clients/systemMenuApiClient';
import { getAccessToken } from '@/utils/cookieHandler';

function getFirstMenuItemHasPermission(menu: MenuListType[]): string {
  for (const menuItem of menu) {
    if (!menuItem?.enabled) {
      continue;
    }
    if (menuItem?.sub?.length) {
      return getFirstMenuItemHasPermission(menuItem?.sub);
    }
    return menuItem.name;
  }
  return '';
}

const RouteGuard = ({ children }: { children: React.ReactNode }) => {
  const [authorized, setAuthorized] = useState(false);
  const router = useRouter();

  const checkRole = useCallback(
    async (currentPath: string) => {
      try {
        if (PUBLIC_PATHS.includes(currentPath)) {
          return;
        }

        const userRole = await getUserRole();

        const havePermissionToCurrentPath = canUserAccessMenu(userRole, currentPath);

        if (currentPath === HOME_PATH) {
          const menu = getMenu(createMenuObject(String(userRole)));

          const firstPageHasPermission = getFirstMenuItemHasPermission(menu);
          if (firstPageHasPermission) {
            router.push(getMenuPathByName(firstPageHasPermission));
          } else {
            router.push(FORBIDDEN_PAGE);
          }
          return;
        }

        if (!havePermissionToCurrentPath && currentPath !== FORBIDDEN_PAGE) {
          router.push(FORBIDDEN_PAGE);
          return;
        }
      } catch (error) {
        router.push(FORBIDDEN_PAGE);
      }
    },
    [router]
  );

  const authCheck = useCallback(
    async (url: string) => {
      const token = getAccessToken();
      const path = url.split('?')[0];

      if (token) {
        await checkRole(path);
        setAuthorized(true);
        return;
      }

      if (!PUBLIC_PATHS.includes(path)) {
        setAuthorized(false);
        router.push({
          pathname: ADMIN_PORTAL_LOGOUT_URL,
        });
      } else {
        setAuthorized(true);
      }
    },
    [checkRole, router]
  );

  useEffect(() => {
    authCheck(router.asPath);

    router.events.on('routeChangeComplete', authCheck);

    return () => {
      router.events.off('routeChangeComplete', authCheck);
    };
  }, [authCheck, router.asPath, router.events]);

  return (
    <>
      {authorized ? (
        children
      ) : (
        <Box height="100vh">
          <LoadingBasic />
        </Box>
      )}
    </>
  );
};

async function getUserRole() {
  const userRoleStorage = Cookies.get(USER_ROLE);

  if (!userRoleStorage) {
    const response = await getSystemMenus();

    Cookies.set(USER_ROLE, response?.toString());

    return response;
  } else {
    return userRoleStorage.split(',');
  }
}

function getMenuPathByName(menuName: string) {
  switch (menuName) {
    case '회원 관리':
    case '회원 조회':
      return '/member-management/inquiry';
    case '휴대폰 인증 초기화':
      return '/member-management/authentication-reset';
    case '블랙리스트 관리':
      return '/block-management';
    case '탈퇴 요청 처리':
      return '/kisa-management';
    default:
      return '';
  }
}

function canUserAccessMenu(menuListName: string[], path: string) {
  const pathPrefixByRole = {
    '회원 관리': '/member-management/inquiry',
    '휴대폰 인증 초기화': '/member-management/authentication-reset',
    '블랙리스트 관리': '/block-management',
    '탈퇴 요청 관리': '/kisa-management',
  } as const;

  const roleName = Object.entries(pathPrefixByRole).find(([, pathPrefix]) => {
    return path.startsWith(pathPrefix);
  })?.[0];

  if (!roleName) {
    return false;
  }

  const roleExists = menuListName.includes(roleName);

  return roleExists;
}

export { RouteGuard };
