import {
  Action,
  combineReducers,
  configureStore,
  PreloadedState,
  ThunkAction,
} from '@reduxjs/toolkit';

import { blockListReducer } from '@/data/client-state/slices/block/blockListSlice';
import { routeReducer } from '@/data/client-state/slices/route/routeSlice';
import { dialogReducer } from '@/data/client-state/slices/ui/dialogSlice';
import { toastReducer } from '@/data/client-state/slices/ui/toastSlice';

export const store = configureStore({
  reducer: {
    toast: toastReducer,
    dialog: dialogReducer,
    blockList: blockListReducer,
    route: routeReducer,
  },
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: combineReducers({
      toast: toastReducer,
      dialog: dialogReducer,
      blockList: blockListReducer,
      route: routeReducer,
    }),
    preloadedState,
  });
};

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
