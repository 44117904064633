import styled from "@emotion/styled";
import { CSSProperties, SelectHTMLAttributes, useRef } from "react";
import { UseFormRegister } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SelectBoxBlock = styled.div<Pick<SelectBoxProps, "width" | "disabled">>`
  position: relative;
  margin-right: 4px;
  width: ${({ width }) => width}px;
  color: ${({ disabled }) => disabled && "#ccc"};
`;

const Select = styled.select<Pick<SelectBoxProps, "error">>`
  width: 100%;
  height: 24px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid ${({ error }) => (error ? "#f00000" : "#ccc")};
  padding: 0 20px 0 10px;
  color: #212121;
  font-size: 8px;
  &:focus {
    border-color: #595959;
  }
  &:disabled {
    pointer-events: none;
    color: #ccc;
    border-color: #ccc;
  }
`;

const DropDownIcon = styled(KeyboardArrowDownIcon)`
  position: absolute;
  top: 3px;
  right: 2px;
  font-size: 18px !important;
  pointer-events: none;
`;

type NativeSelectPropsToOmit = "width" | "style";
type NativeSelectProps = SelectHTMLAttributes<HTMLSelectElement>;

type SelectBoxProps = Omit<NativeSelectProps, NativeSelectPropsToOmit> & {
  register: BlockReasonCategoryFormRegister;
  width?: string;
  error?: string;
  style?: CSSProperties;
};

export const SelectBoxReasonCategory = ({
  width = "80",
  disabled,
  children,
  register,
  ...props
}: SelectBoxProps) => {
  return (
    <SelectBoxBlock width={width} disabled={disabled}>
      <Select
        {...props}
        disabled={disabled}
        {...register("blockReasonCategory", {
          required: {
            message: "아이디를 입력해주세요.",
            value: true,
          },
        })}
      >
        {children}
      </Select>
      <DropDownIcon />
    </SelectBoxBlock>
  );
};

type BlockReasonCategoryFormRegister = UseFormRegister<{
  blockReasonCategory: string;
}>;
