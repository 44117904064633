import Navigation from "./SideNavigationBar";

import { useRouter } from "next/router";
import { useMemo, useState } from "react";

export const initialOpenedMenuState: string[] = [
  "회원 관리",
  "블랙리스트 관리",
  "R&D 기능 설정",
];

interface MenuType {
  name: string;
  enabled: boolean;
}

export type MenuListType = MenuType & { sub: MenuType[]; path?: string };

type MenuName = Pick<MenuListType, "name">;

const openMenu = (state: string[], { name }: MenuName) => state.concat([name]);

const closeMenu = (state: string[], { name }: MenuName) =>
  state.filter((opened: string) => opened !== name);

const toggleMenu = (state: string[], { name }: MenuName) =>
  (state.includes(name) ? closeMenu : openMenu)(state, { name });

type MenuAction = typeof openMenu | typeof closeMenu | typeof toggleMenu;

interface MenuOpenedStateReturnType {
  openedMenuState: string[];
  toggle: (menu: MenuListType[]) => void;
  open: (menu: MenuListType[]) => void;
  close: (menu: MenuListType[]) => void;
}

const useMenuOpenedState = (
  initialState: string[]
): MenuOpenedStateReturnType => {
  const [menuStatusMap, setMenuStatusMap] = useState(initialState);

  const setManyState = (fn: MenuAction) => (menu: MenuListType[]) =>
    setMenuStatusMap(menu.reduce(fn, menuStatusMap));

  return {
    openedMenuState: menuStatusMap,
    toggle: setManyState(toggleMenu),
    open: setManyState(openMenu),
    close: setManyState(closeMenu),
  };
};

// TODO: Replace dummyMenuPermissions with permissions from the API.
const dummyMenuPermissions = {
  "회원 관리": true,
  "회원 조회": true,
  "휴대폰 인증 초기화": true,
  "블랙리스트 관리": true,
  "R&D 기능 설정": true,
  "KISA 탈퇴 요청 처리": true,
};

export interface NavigationContainerProps {
  show: boolean;
  getMenuByPath: (path: string) => string;
  getPathByMenuName: (name: string) => string;
  getMenu: (permissions: Record<string, boolean>) => MenuListType[];
}

export const SideNavigationBarContainer = ({
  show,
  getMenu,
  getMenuByPath,
  getPathByMenuName,
}: NavigationContainerProps): JSX.Element => {
  const router = useRouter();
  const selectedMenu = getMenuByPath(router.pathname);

  const menu: MenuListType[] = useMemo(
    () => getMenu(dummyMenuPermissions),
    [dummyMenuPermissions]
  );

  const { openedMenuState, toggle, open, close } = useMenuOpenedState(
    initialOpenedMenuState
  );

  const handleClickOnMenu = (name: string) => {
    const menuItem = menu.find((item) => item.name === name);

    // menu has no submenu
    if (!menuItem?.sub.length && menuItem?.path) {
      const menuPath = getPathByMenuName(menuItem?.name);
      if (menuPath) {
        router.push(menuPath);
      }
    }

    // menu has submenu
    if (menuItem?.sub[0]?.name && selectedMenu !== menuItem?.sub[0]?.name) {
      const subPath = getPathByMenuName(menuItem?.sub[0]?.name);
      if (subPath) {
        router.push(subPath);
      }
    }
  };

  return (
    <Navigation
      show={show}
      menu={menu}
      openedMenuState={openedMenuState}
      selectedMenu={selectedMenu}
      toggle={toggle}
      open={open}
      close={close}
      handleClickOnMenu={handleClickOnMenu}
      getPathByMenuName={getPathByMenuName}
    />
  );
};
