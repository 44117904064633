import styled from "@emotion/styled";
import { TableListCell } from "../organisms/table/BaseTableParts";

const MaskedDataCellCustom = styled(TableListCell)`
  white-space: nowrap;
`;

type MaskedDataCellProps = {
  text: string;
  findTextToMaskRegEx?: RegExp;
  regexStr?: string;
};

export const MaskedDataCell = ({
  text,
  findTextToMaskRegEx = /(\d{3})-\d{4}-(\d{4})/,
  regexStr = "$1-****-$2",
}: MaskedDataCellProps) => {
  const maskedText = text?.replace(findTextToMaskRegEx, regexStr);

  return <MaskedDataCellCustom>{maskedText}</MaskedDataCellCustom>;
};
