import Cookies from 'js-cookie';
import { NextRouter } from 'next/router';

import { JWT_NAME, REFRESH_TOKEN_NAME, USER_NAME, USER_ROLE } from '@/constants/cookieName';
import { ADMIN_PORTAL_LOGOUT_URL } from '@/constants/routePath';

export const handleLogout = (nextRouter?: NextRouter) => {
  if (nextRouter) {
    nextRouter.push(ADMIN_PORTAL_LOGOUT_URL);
  } else {
    window.location.replace(ADMIN_PORTAL_LOGOUT_URL);
  }
  clearAuthStorage();
};

const getCookieDomain = (url: string | undefined) => {
  if (!url) {
    return '';
  }
  return url.replace(/(https?:\/\/)?[^.]+(\..+)/, '$2');
};

export const clearAuthStorage = () => {
  sessionStorage.clear();
  const authCookieDomain = getCookieDomain(process.env.NEXT_PUBLIC_ADMIN_PORTAL_URL);
  Cookies.remove(JWT_NAME, { domain: authCookieDomain });
  Cookies.remove(REFRESH_TOKEN_NAME, { domain: authCookieDomain });
  Cookies.remove(USER_NAME);
  Cookies.remove(USER_ROLE);
};
