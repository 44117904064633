import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useRef,
} from "react";

import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import mergeRefs from "../../utils/mergeRefs";

interface TextFieldStyledProps {
  withIcon?: boolean;
  error?: string;
  errorWithoutText?: boolean;
}

const TextFieldBlock = styled.div<Pick<TextFieldProps, "width">>`
  width: ${({ width }) => width ?? null};
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  &.block_base {
    position: unset;
  }
`;

const Input = styled.input<
  Pick<TextFieldProps, "width" | "height" | "customCss"> & TextFieldStyledProps
>`
  box-sizing: border-box;
  width: ${({ width }) => (width && width !== "100%" ? width + "px" : "100%")};
  height: ${({ height }) => height + "px"};
  padding: ${({ withIcon }) => (withIcon ? "0 20px 0 10px" : "0 10px")};
  background: ${({ customCss }) => customCss?.background ?? "#fff"};
  border: ${({ customCss }) => customCss?.border ?? "1px solid #ccc"};
  margin-right: 4px;
  color: #212121;
  font-size: 12px;
  line-height: 24px;
  ${({ error }) =>
    error &&
    `
      border: 1px solid #f00000;
      margin-bottom: 17px;
    `}
  &:focus {
    border-color: #595959;
  }
  &:disabled {
    border-color: #e6e6e6;
    background-color: #f5f5f5;
    color: #999;
  }
  ::placeholder {
    color: #999;
  }
  &[type="date"] {
    padding-right: 0;
  }
  &[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  ${({ errorWithoutText }) =>
    errorWithoutText &&
    `
    border: 1px solid #f00000;
  `}
`;

export const FormHelperText = styled.div<Pick<TextFieldStyledProps, "error">>`
  display: ${({ error }) => (error ? "block" : "none")};
  color: #ff0000;
  font-size: 11px;
`;

type TextFieldExtraProps = {
  className?: string;
  style?: CSSProperties;
  customCss?: CSSProperties;
  defaultValue?: string | number;
  textLength?: number;
};

type TextFieldProps = InputHTMLAttributes<HTMLInputElement> &
  TextFieldStyledProps &
  TextFieldExtraProps;

const InnerTextField = (
  {
    className,
    width,
    height,
    error,
    type,
    max,
    maxLength,
    textLength,
    ...props
  }: TextFieldProps,
  outerRef: ForwardedRef<HTMLInputElement>
) => {
  const innerRef = useRef<HTMLInputElement>(null);
  const baseRef = mergeRefs(outerRef, innerRef);

  return (
    <TextFieldBlock className={className} width={width}>
      <Input
        type={type}
        width={width ?? 140}
        height={height ?? 24}
        ref={baseRef}
        max={type === "date" ? max ?? "9999-12-31" : undefined}
        maxLength={maxLength}
        {...props}
      />
      <FormHelperText error={error}>{error}</FormHelperText>
      {!!maxLength && !!textLength && (
        <Stack justifyContent={"flex-start"}>
          {textLength}/{maxLength}
        </Stack>
      )}
    </TextFieldBlock>
  );
};

export const TextField = forwardRef(InnerTextField);
