import React, { CSSProperties, FC } from "react";
import {
  Dialog as MDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button as MButton,
} from "@mui/material";

interface ButtonProps {
  label: string;
  handleClick: () => void;
}

interface DialogProps {
  openDialog: boolean;
  setOpenDialog: (value: React.SetStateAction<boolean>) => void;
  contentText: string | JSX.Element;
  buttons: ButtonProps[];
  paperStyles?: CSSProperties | undefined;
}

export const Dialog: FC<DialogProps> = ({
  openDialog,
  setOpenDialog,
  contentText,
  buttons,
  paperStyles,
  ...props
}) => {
  return (
    <MDialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      PaperProps={{
        style: {
          boxShadow: "none",
          padding: "10px 10px 20px 0",
          ...paperStyles,
        },
      }}
      {...props}
    >
      <DialogContent>
        <DialogContentText
          color="inherit"
          variant="h6"
          style={{ whiteSpace: "pre-line" }}
        >
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map(({ label, handleClick }, idx: number) => (
          <MButton
            key={idx}
            onClick={() => handleClick()}
            variant="contained"
            color="primary"
            size="large"
          >
            {label}
          </MButton>
        ))}
      </DialogActions>
    </MDialog>
  );
};
