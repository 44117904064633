import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

type PaginationProps = {
  handlePrevPageClick: () => void;
  handleNextPageClick: () => void;
  isPrePageDisabled: boolean;
  isNextPageDisabled: boolean;
};

const StyledPagination = styled(Stack)<{ isDisabled: boolean }>`
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.gray[30] : "unset"};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

export const Pagination = (props: PaginationProps) => {
  const onClickPrePage = () => {
    if (props.isPrePageDisabled) {
      return;
    }

    props.handlePrevPageClick();
  };

  const onClickNextPage = ()=>{
    if (props.isNextPageDisabled) {
        return;
      }
  
      props.handleNextPageClick();
  }

  return (
    <Stack direction={"row"} gap={1} marginBottom={1} marginTop={1}>
      <StyledPagination
        direction={"row"}
        alignItems={"center"}
        onClick={onClickPrePage}
        isDisabled={props.isPrePageDisabled}
      >
        <ArrowLeft /> 이전 페이지
      </StyledPagination>

      <StyledPagination
        direction={"row"}
        alignItems={"center"}
        onClick={onClickNextPage}
        isDisabled={props.isNextPageDisabled}
      >
        다음 페이지 <ArrowRight />
      </StyledPagination>
    </Stack>
  );
};
