import { useEffect, useCallback, useRef, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

export const useResizeObserver = () => {
  const [screenWidth, setScreenWidth] = useState<number>();

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) return;

      const entry = entries[0];

      setScreenWidth(entry.contentRect.width);
    },
    [setScreenWidth]
  );

  const resizeObserver = useRef<ResizeObserver | null>(
    new ResizeObserver((entries) => {
      handleResize(entries);
    })
  );

  const bodyRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    bodyRef.current = window.document.body;
  }, []);

  useEffect(() => {
    if (!bodyRef.current) return;

    resizeObserver.current?.observe(bodyRef.current);

    return () => {
      resizeObserver.current?.disconnect();
      resizeObserver.current = null;
    };
  }, [bodyRef]);

  return {
    screenWidth,
  };
};
