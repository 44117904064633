import { isEmpty, join, match, pipe, reject, replace, tail } from "ramda";

export type Types =
  | "company_number"
  | "phone"
  | "resident_number"
  | "license_number"
  | "corp_number"
  | "liquor_number";

type RegExTypes = Exclude<Types, "phone"> | "phone_12" | "phone_13";

const regex: Record<RegExTypes, RegExp> = {
  company_number: /(.{0,3})(.{0,2})(.{0,5})/,
  phone_12: /(.{0,3})(.{0,3})(.{0,4})/,
  phone_13: /(.{0,3})(.{0,4})(.{0,4})/,
  resident_number: /(.{0,6})(.{0,7})/,
  license_number: /(.{0,2})(.{0,2})(.{0,6})(.{0,2})/,
  corp_number: /(.{0,6})(.{0,7})/,
  liquor_number: /(.{0,3})(.{0,1})(.{0,5})/,
};

const getRegEx = (type: Types, val?: string) => {
  const hasHyphen = val?.includes("-") ?? false;
  const phoneLengthCondition = hasHyphen ? 12 : 10;
  const typeNumber = (val ?? "").length > phoneLengthCondition ? 13 : 12;

  const regexType = type === "phone" ? `${type}_${typeNumber}` : type;

  return regex[regexType as RegExTypes];
};

export const getFormattedValue = (type: Types, value: string) =>
  pipe(
    replace(/[^0-9]/g, ""),
    match(getRegEx(type, value)),
    tail,
    reject(isEmpty),
    join("-")
  )(value);
