import { useEffect, useState } from "react";
import { colors } from "../../styles/colors";
import { fonts } from "../../styles/fonts";
import {
  DEFAULT_DEVICE_SIZES,
  createBreakpoints,
} from "../../styles/utils/create-break-points";
import { ColorMode } from "../../styles/theme";

type UseThemeProps = {
  colorMode: ColorMode;
};

export const useTheme = ({ colorMode }: UseThemeProps) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (colorMode === "dark") {
      setIsDarkMode(true);
      return;
    }

    if (colorMode === "light") {
      setIsDarkMode(false);
      return;
    }

    setIsDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
  }, []);

  return {
    service: "consumer",
    colors: colors[isDarkMode ? "dark" : "light"],
    fonts,
    breakpoints: createBreakpoints(),
    devices: DEFAULT_DEVICE_SIZES,
  };
};

export const defaultTheme: ReturnType<typeof useTheme> = {
  service: "consumer",
  colors: colors["light"],
  fonts,
  breakpoints: createBreakpoints(),
  devices: DEFAULT_DEVICE_SIZES,
} as const;
