import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton, Stack, Typography } from "@mui/material";
import { default as MuiBreadcrumbs } from "@mui/material/Breadcrumbs";
import Link from "next/link";
import { useRouter } from "next/router";

const fontStyle = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: black;
`;

const BreadcrumbText = styled(Typography)`
  ${fontStyle};
`;

const BreadcrumbLink = styled(Link)`
  ${fontStyle};
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

export interface BreadcrumbItem {
  name: string;
  redirect: string;
}

type BreadcrumbProps = {
  items: BreadcrumbItem[];
  enableBack?: boolean;
  backUrl?: string;
  lastActive?: boolean;
};
export default function Breadcrumb({
  items = [],
  enableBack,
  backUrl,
  lastActive = true,
}: BreadcrumbProps) {
  const router = useRouter();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {enableBack ? (
        <IconButton
          sx={{ color: "black" }}
          aria-label="back"
          onClick={() => (backUrl ? router.push(backUrl) : router.back())}
        >
          <ArrowCircleLeftIcon fontSize="large" />
        </IconButton>
      ) : null}

      <MuiBreadcrumbs
        separator={<ArrowForwardIosIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {items.map((breadcrumbItem: BreadcrumbItem, index) => {
          const currPath = breadcrumbItem.redirect;
          const isLastItem = index === items.length - 1;

          const elementKey = `breadcrumbs_${breadcrumbItem.name}`;
          const elementLabel = breadcrumbItem.name;

          if (isLastItem && !lastActive) {
            return (
              <BreadcrumbText key={elementKey}>{elementLabel}</BreadcrumbText>
            );
          }

          return (
            <BreadcrumbLink key={elementKey} href={currPath}>
              {elementLabel}
            </BreadcrumbLink>
          );
        })}
      </MuiBreadcrumbs>
    </Stack>
  );
}
