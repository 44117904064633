import { ReactElement, ReactNode } from 'react';
import {
  BOTTOMSHEET_PADDING,
  BOUND_GAP,
  ANIMATION_DURATION,
  HALF_HEIGHT,
} from './common/constants';
import styled from '@emotion/styled';

type ContainerProps = {
  hasBottom: boolean;
  height: string;
};

type Props = ContainerProps & {
  children: ReactNode;
};

const Container = styled.div<ContainerProps>`
  flex: 0 1 auto;
  overflow-y: auto;
  padding: 0 16px;

  height: ${({ height }) => height};
  transition: ${ANIMATION_DURATION}ms cubic-bezier(0, 1.15, 0.4, 1.01);

  margin-bottom: ${({ hasBottom }) =>
    hasBottom ? `${BOTTOMSHEET_PADDING}px` : `${BOTTOMSHEET_PADDING + BOUND_GAP}px`};
`;

export function Body({ hasBottom, height = HALF_HEIGHT, children }: Props): ReactElement {
  return (
    <Container hasBottom={hasBottom} height={height}>
      {children}
    </Container>
  );
}
