import { SerializedStyles } from "@emotion/react";

interface FormProps {
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  css?: SerializedStyles;
  name?: string;
}

export const Form = ({ children, onSubmit, ...props }: FormProps) => {
  return (
    <form onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};
