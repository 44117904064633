import styled from "@emotion/styled";
import { convertToCustomDateFormat } from "../../../utils";
import { TableListCell } from "./BaseTableParts";

const DateCellCustom = styled(TableListCell)`
  white-space: nowrap;
`;

type DateCellProps = {
  date: string;
  dateTimeFormatter: string;
  defaultDateValue?: string;
};
export default function DateCell({
  date,
  dateTimeFormatter = "yy/MM/dd",
  defaultDateValue = "",
}: DateCellProps) {
  const dateFormatted = dateTimeFormatter ? convertToCustomDateFormat(date, dateTimeFormatter, true) : defaultDateValue;
  return <DateCellCustom>{dateFormatted}</DateCellCustom>;
}
