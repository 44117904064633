import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAppDispatch } from '@/data/client-state/hooks/useStore';
import { setPreviousRoute } from '@/data/client-state/slices/route/routeSlice';

export const usePreviousRoute = () => {
  const { asPath } = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPreviousRoute(asPath));
  }, [asPath, dispatch]);
};
