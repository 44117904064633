const { DateTime } = require("luxon");

export function convertToCustomDateFormat(
  dateTime?: string | Date,
  dateTimeFormatter = "yyyy/MM/dd",
  defaultEmpty?: boolean
) {
  let date;

  if (!dateTime && defaultEmpty) {
    return "";
  }

  if (!dateTime) {
    date = DateTime.now();
  } else {
    date =
      dateTime instanceof Date
        ? DateTime.fromJSDate(dateTime)
        : DateTime.fromISO(dateTime);
  }

  return date.toFormat(dateTimeFormatter);
}

export const resetDateWithUTCTime = (
  date: Date | null | undefined,
  type: "start" | "end"
) => {
  if (!date) return null;
  let dateFormatted = DateTime.utc(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
  );

  if (type === "start") {
    dateFormatted = dateFormatted.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  if (type === "end") {
    dateFormatted = dateFormatted.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
  }

  return dateFormatted;
};

export function convertToUtcFormat(dateTime?: string | Date | null) {
  let date;

  if (!dateTime) {
    return "";
  } else {
    date =
      dateTime instanceof Date
        ? DateTime.fromJSDate(dateTime)
        : DateTime.fromISO(dateTime);
  }

  return date.toUTC();
}
