import { FC, MouseEvent, ReactElement } from "react";
import { CloseButton } from "../atoms/Button";
import { ErrorOutline as ErrorIcon } from "@mui/icons-material";
import styled from "@emotion/styled";

export interface StyleProps {
  width?: number;
}

const PopupWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

const PopupBlock = styled.div<StyleProps>`
  position: relative;
  width: ${(props) => props.width}px;
  max-height: calc(100vh - 100px);
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #fff;
  .popup_close {
    position: absolute;
    top: 18px;
    right: 20px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 62px;
  position: fixed;
  left: 0;
  top: 0;
`;

const Title = styled.h2`
  width: 100%;
  height: 100%;
  padding: 24px 0 0 20px;
  box-sizing: border-box;
  color: #343b46;
  font-size: 20px;
  border-bottom: 1px solid #d9d9d9;
`;

const Content = styled.div`
  padding: 16px 20px 20px;
  overflow: scroll;
  width: 100%;
  position: relative;
  max-height: calc(100vh - 100px);
  top: 62px;
  padding-bottom: 80px;
`;

export const ErrorCaseIcon = styled(ErrorIcon)`
  display: block !important;
  margin: 0 auto 10px;
  color: #eee;
  font-size: 56px !important;
`;

export interface Props {
  title?: string;
  setPopup: (state: boolean) => void;
  onClose?: () => void;
  children: ReactElement[] | ReactElement;
}

const Popup: FC<Props & StyleProps> = ({
  width = 480,
  title = "",
  setPopup,
  onClose,
  children,
  ...restProps
}) => {
  const handleClose = (
    e: MouseEvent<HTMLButtonElement | HTMLDivElement | SVGSVGElement>
  ) => {
    if (!(e.target as HTMLDivElement).classList.contains("popup_close")) {
      return;
    }

    e.stopPropagation();

    if (onClose) {
      return onClose();
    }

    setPopup(false);
  };

  return (
    <PopupWrap {...restProps} onClick={handleClose}>
      <PopupBlock width={width}>
        <TitleWrapper>
          {title && <Title>{title}</Title>}
          <CloseButton className="popup_close" onClick={handleClose} />
        </TitleWrapper>
        <Content>{children}</Content>
      </PopupBlock>
    </PopupWrap>
  );
};

export default Popup;
