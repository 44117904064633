import styled from '@emotion/styled';
import React, { FC } from 'react';
import Popup, { Props, StyleProps } from './Popup';

export const ScrollContentBasic = styled.div`
  max-height: calc(100vh - 420px);
  overflow-y: scroll;
`;

export const FooterActionBasic = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
`;

const CustomStyle = styled.div``;

export const Modal: FC<Props & StyleProps> = ({
  width = 720,
  title = '',
  setPopup,
  onClose,
  children,
}) => {
  return (
    <CustomStyle>
      <Popup width={width} title={title} setPopup={setPopup} onClose={onClose}>
        <div style={{ position: 'relative' }}>{children}</div>
      </Popup>
    </CustomStyle>
  );
};
