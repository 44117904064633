import styled from "@emotion/styled";
import { FC, MouseEvent, ReactElement } from "react";

export interface BasePopupStyleProps {
  width?: number;
}

const PopupWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 50;
`;

const PopupBlock = styled.div<BasePopupStyleProps>`
  position: relative;
  width: ${(props) => props.width}px;
  max-height: calc(100vh - 100px);
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #fff;
  .popup_close {
    position: absolute;
    top: 18px;
    right: 20px;
  }
`;

export interface BasePopupProps {
  title?: string;
  setPopup: (state: boolean) => void;
  onClose?: () => void;
  children: ReactElement[] | ReactElement;
  isCustomPopup?: boolean;
}

const BasePopup: FC<BasePopupProps & BasePopupStyleProps> = ({
  width = 720,
  setPopup,
  onClose,
  children,
}) => {
  const handleClose = (e: MouseEvent<HTMLElement>) => {
    if (!e.currentTarget.classList.contains("popup_close")) {
      return;
    }

    e.stopPropagation();

    if (onClose) {
      return onClose();
    }

    setPopup(false);
  };

  return (
    <PopupWrap onClick={handleClose}>
      <PopupBlock width={width}>{children}</PopupBlock>
    </PopupWrap>
  );
};

export default BasePopup;
