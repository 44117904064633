import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DialogState = {
  displayUnauthenticatedDialog: boolean;
  messageUnauthenticatedDialog: string;
};

const initialState: DialogState = {
  displayUnauthenticatedDialog: false,
  messageUnauthenticatedDialog: '',
};

export const dialogSlice = createSlice({
  name: 'dialogSlice',
  initialState,
  reducers: {
    showUnauthenticatedDialog: (_, action: PayloadAction<string>) => ({
      messageUnauthenticatedDialog: action.payload,
      displayUnauthenticatedDialog: true,
    }),
    hideUnauthenticatedDialog: () => ({
      messageUnauthenticatedDialog: '',
      displayUnauthenticatedDialog: false,
    }),
  },
});

export const dialogReducer = dialogSlice.reducer;

export const { showUnauthenticatedDialog } = dialogSlice.actions;
