const primary = {
  90: '#BB0010',
  80: '#C9151E',
  70: '#D61F26',
  60: '#E82B2B',
  50: '#ED3D34',
  40: '#EB5452',
  30: '#E86D6D',
  20: '#F29796',
  10: '#FFCBD0',
  5: '#FFEAED',
} as const;

const secondary = {
  90: '#262B33',
  80: '#383F4A',
  70: '#48515F',
  60: '#596576',
  50: '#667387',
  40: '#7C8798',
  30: '#939CAA',
  20: '#B2B8C2',
  10: '#D0D4DA',
  5: '#EDEEEF',
} as const;

const blue = {
  90: '#0D48A1',
  80: '#1666C0',
  70: '#1A77D2',
  60: '#1F89E5',
  50: '#2397F3',
  40: '#43A6F5',
  30: '#65B6F6',
  20: '#91CBF9',
  10: '#BBDEFB',
  5: '#E3F2FD',
} as const;

const green = {
  90: '#005E2C',
  80: '#007E40',
  70: '#008F4B',
  60: '#0EA158',
  50: '#19B062',
  40: '#49BC79',
  30: '#6DC890',
  20: '#99D7B0',
  10: '#C1E7CE',
  5: '#E5F5EB',
} as const;

const red = {
  90: '#C70000',
  80: '#E30000',
  70: '#F7061A',
  60: '#FF1E30',
  50: '#FF434E',
  40: '#FF6B6A',
  30: '#FF8A85',
  20: '#FFADA8',
  10: '#FFCECA',
  5: '#FFEAEC',
} as const;

const gray = {
  90: '#BDBDBD',
  87: '#212121',
  80: '#333333',
  70: '#4D4D4D',
  65: '#595959',
  50: '#808080',
  40: '#999999',
  30: '#B3B3B3',
  20: '#CCCCCC',
  11: '#A8A8A8',
  10: '#E6E6E6',
  9: '#BABABA',
  8: '#EBEBEB',
  4: '#F5F5F5',
  2: '#F9F9F9',
} as const;

const commonColors = {
  base: gray[87],
  white: 'hsla(0, 0%, 100%, 1)',
  lightGray: 'hsla(0, 0%, 95%, 1)',
  black: 'hsla(0, 0%, 0%, 1)',
  deepDark: 'hsla(0, 0%, 6%, 1)',
  dark: 'hsla(0, 0%, 11%, 1)',
  lightDark: 'hsla(0, 0%, 15%, 1)',
} as const;

export const light = {
  primary,
  secondary,
  blue,
  green,
  red,
  gray,
  ...commonColors,
} as const;

export const dark = {
  primary,
  secondary,
  blue,
  green,
  red,
  gray,
  ...commonColors,
} as const;

const lightModeBackgroundColors = {
  topBg: light.white,
  primaryBg: light.white,
  bottomBg: light.lightGray,
  topBgI: light.lightDark,
  primaryBgI: light.dark,
  bottomBgI: light.deepDark,
  dimBg: light.black,
  notiBg: light.black,
} as const;

const darkModeBackgroundColors = {
  topBg: dark.white,
  primaryBg: dark.white,
  bottomBg: dark.lightGray,
  topBgI: dark.lightDark,
  primaryBgI: dark.dark,
  bottomBgI: dark.deepDark,
  dimBg: dark.black,
  notiBg: dark.black,
} as const;

export const colors = {
  light: { ...light, ...lightModeBackgroundColors },
  dark: { ...dark, ...darkModeBackgroundColors },
} as const;

export type ColorScheme = {
  light: typeof colors.light;
  dark: typeof colors.dark;
};
