import { Interpolation, Theme } from '@emotion/react';

type DefaultTheme = Theme;
type FlattenInterpolation<Props> = Interpolation<Props>;
type ThemeProps<Props> = { theme?: Theme } & Props;

type WithBreakpoints = FlattenInterpolation<ThemeProps<DefaultTheme>>;

const baseSF = <FontSize extends number, LineHeight extends number>(options: {
  fontSize: FontSize;
  lineHeight: LineHeight;
}) => `font-size: ${options.fontSize}px; line-height: ${options.lineHeight}px;` as const;

const regular = <BaseSF extends string>(baseSF: BaseSF) =>
  `${baseSF} font-weight: normal;` as const;
const bold = <BaseSF extends string>(baseSF: BaseSF) => `${baseSF} font-weight: bold;` as const;

export const SF_10 = baseSF({ fontSize: 10, lineHeight: 14 });
export const SF_10_R = regular(SF_10);
export const SF_10_B = bold(SF_10);

export const SF_11 = baseSF({ fontSize: 11, lineHeight: 15 });
export const SF_11_R = regular(SF_11);
export const SF_11_B = bold(SF_11);

export const SF_12 = baseSF({ fontSize: 12, lineHeight: 16 });
export const SF_12_R = regular(SF_12);
export const SF_12_B = bold(SF_12);

export const SF_13 = baseSF({ fontSize: 13, lineHeight: 18 });
export const SF_13_R = regular(SF_13);
export const SF_13_B = bold(SF_13);

export const SF_14 = baseSF({ fontSize: 14, lineHeight: 19 });
export const SF_14_R = regular(SF_14);
export const SF_14_B = bold(SF_14);

export const SF_15 = baseSF({ fontSize: 15, lineHeight: 20 });
export const SF_15_R = regular(SF_15);
export const SF_15_B = bold(SF_15);

export const SF_16 = baseSF({ fontSize: 16, lineHeight: 22 });
export const SF_16_R = regular(SF_16);
export const SF_16_B = bold(SF_16);

export const SF_18 = baseSF({ fontSize: 18, lineHeight: 24 });
export const SF_18_R = regular(SF_18);
export const SF_18_B = bold(SF_18);

export const SF_20 = baseSF({ fontSize: 20, lineHeight: 27 });
export const SF_20_R = regular(SF_20);
export const SF_20_B = bold(SF_20);

export const SF_22 = baseSF({ fontSize: 22, lineHeight: 30 });
export const SF_22_R = regular(SF_22);
export const SF_22_B = bold(SF_22);

export const SF_24 = baseSF({ fontSize: 24, lineHeight: 32 });
export const SF_24_R = regular(SF_24);
export const SF_24_B = bold(SF_24);

export const SF_32 = baseSF({ fontSize: 32, lineHeight: 43 });
export const SF_32_R = regular(SF_32);
export const SF_32_B = bold(SF_32);

export const SF_56 = baseSF({ fontSize: 56, lineHeight: 76 });
export const SF_56_R = regular(SF_56);
export const SF_56_B = bold(SF_56);

export const fonts = {
  title1: SF_32_B,
  title2: SF_24_B,
  title3: SF_24_R,
  title4: SF_20_B,
  title5: SF_20_R,
  title6: SF_18_B,
  title7: SF_18_R,
  title8: SF_16_B,
  title9: SF_16_R,

  subTitle1: SF_16_B,
  subTitle2: SF_16_R,
  subTitle3: SF_14_B,
  subTitle4: SF_14_R,

  body1: SF_16_B,
  body2: SF_16_R,
  body3: SF_15_B,
  body4: SF_15_R,
  body5: SF_14_B,
  body6: SF_14_R,
  body7: SF_13_B,
  body8: SF_13_R,
  body9: SF_12_B,
  body10: SF_12_R,
  body11: SF_11_B,
  body12: SF_11_R,
  body13: SF_10_R,
} as const;

type Fonts = typeof fonts;
type FontsKeys = keyof Fonts;

export type FontScheme = {
  [key in FontsKeys]: [Fonts[FontsKeys]] | WithBreakpoints;
};
