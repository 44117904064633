import { useState } from "react";
import { SelectBoxOption } from "./SelectBox";

export const useSelectBox = (initialOptions: SelectBoxOption[]) => {
  const [options, setOptions] = useState<SelectBoxOption[]>(initialOptions);

  const handleSelectBoxChange = (selectedOption: SelectBoxOption) => {
    setOptions((prevOptions) => {
      return prevOptions.map((option) => {
        if (option.name === selectedOption.name) {
          return { ...option, selected: true };
        }
        return { ...option, selected: false };
      });
    });
  };

  return { options, handleSelectBoxChange };
};
