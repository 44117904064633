import { forwardRef, ReactElement, ReactNode, useEffect, useState, MouseEvent } from 'react';
import { ANIMATION_DURATION } from './common/constants';
import { Underlay, Wrapper } from './common/styled';
import { ContainerProps } from './common/type';
import ExtensionBar from './Extensionbar';

export type WrapperProps = {
  isOpen: boolean;
  hasTitle: boolean;
};

export const BottomSheetContainer = forwardRef<HTMLDivElement, ContainerProps>(
  function BottomSheetContainer(
    {
      children,
      isOpen,
      extensionbar,
      onClose,
      hasTitle,
      bottomSheetHeightState,
      zIndex,
      underlay = true,
      defaultHeight,
    },
    ref
  ): ReactElement {
    const [delayIsOpen, setDelayIsOpen] = useState<boolean>(isOpen);

    useEffect(() => {
      const count = isOpen === true ? 0 : ANIMATION_DURATION;

      const timeout = setTimeout(() => {
        setDelayIsOpen(isOpen);
      }, count);

      return () => clearTimeout(timeout);
    }, [isOpen]);

    useEffect(() => {
      if (typeof window === 'undefined' || underlay === false) {
        return;
      }

      if (isOpen) {
        window.document.body.style.overflow = 'hidden';
      } else {
        window.document.body.style.overflow = 'auto';
      }
    }, [isOpen]);

    return (
      <BottomSheet underlay={underlay} isOpen={isOpen} delayIsOpen={delayIsOpen} zIndex={zIndex}>
        <Wrapper isOpen={isOpen} hasTitle={hasTitle} ref={ref}>
          {extensionbar && (
            <ExtensionBar
              onClose={onClose}
              bottomSheetHeightState={bottomSheetHeightState}
              defaultHeight={defaultHeight}
            />
          )}
          {children}
        </Wrapper>
      </BottomSheet>
    );
  }
);

type BottomSheetProps = {
  underlay: boolean;
  delayIsOpen: boolean;
  children: ReactNode;
} & Pick<ContainerProps, 'isOpen' | 'zIndex'>;

const BottomSheet = ({ underlay, children, isOpen, delayIsOpen, zIndex }: BottomSheetProps) => {
  if (!underlay) {
    return <>{children}</>;
  }

  const stopPropagationEvent = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Underlay isOpen={isOpen} show={delayIsOpen} zIndex={zIndex} onClick={stopPropagationEvent}>
      {children}
    </Underlay>
  );
};
