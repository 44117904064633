const MEMBERYO_API_STAGING_URL_BY_FE_STAGING_TYPE: {
  [key: string]: string;
} = {
  e: 'https://memberyo-admin-e.staging.yogiyo.co.kr',
  g: 'https://memberyo-admin-g.staging.yogiyo.co.kr',
};

const FE_STAGING_URL_FLAG = 'staging.yogiyo.co.kr';
const FE_MULTI_STAGING_URL_CHUNK = 'admin-';

export function getMemberyoApiUrl(feOrigin: string) {
  const DEFAULT_API_URL = process.env.NEXT_PUBLIC_MEMBERYO_API_URL;

  const isFeStaging = feOrigin.includes(FE_STAGING_URL_FLAG);

  if (!isFeStaging) {
    return DEFAULT_API_URL;
  }

  const feStagingTypeUrlChunk = feOrigin
    .split('.')
    .find((originChunk) => originChunk.startsWith(FE_MULTI_STAGING_URL_CHUNK));

  if (!feStagingTypeUrlChunk) {
    return DEFAULT_API_URL;
  }

  // NOTE: The e-staging of the FE app is "memberyo.admin-e.staging.yogiyo.co.kr", so feStagingType would be "e"
  const feStagingType = feStagingTypeUrlChunk.split('-')[1];

  const memberyoStagingUrl = MEMBERYO_API_STAGING_URL_BY_FE_STAGING_TYPE[feStagingType];

  if (!memberyoStagingUrl) {
    console.error(`Could not find Memberyo staging url with the fe-staging type ${feStagingType}`);
    return DEFAULT_API_URL;
  }

  return memberyoStagingUrl;
}
