import { isEmpty, isNil } from 'ramda';
import { object, string } from 'zod';

const makeValidator = (reg: RegExp) => (value: string) => reg.test(value);

export const getSearchDateSchema = (start: string, end: string) =>
  object({
    [start]: string().regex(/\d{4}-\d{2}-\d{2}/, {
      message: '조회시작일을 확인해주세요.',
    }),
    [end]: string()
      .regex(/\d{4}-\d{2}-\d{2}/, {
        message: '조회종료일을 확인해주세요.',
      })
      .refine(
        (val) => {
          if (isEmpty(val)) {
            return true;
          }
          const startDate = new Date(start);
          const endDate = new Date(end);
          return startDate <= endDate;
        },
        {
          message: '조회시작일을 조회종료일 이전으로 선택해주세요.',
        }
      ),
  });

export const isNumeric = (inputValue: string | undefined): boolean => {
  return !(isNil(inputValue) || isNaN(parseFloat(inputValue))) && isFinite(Number(inputValue));
};

export const isValidUserId = makeValidator(/^[a-z0-9-_.]{5,20}$/);

export const isValidUserName = makeValidator(/^[ㄱ-ㅎㅏ-ㅣ가-힣]{2,}$/);

export const isValidEmail = makeValidator(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
