import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Stack } from "@mui/material";
import { StripedGridItem } from "ui";
import { ColumnType } from "./type";

type Props = {
  title?: string;
  columns: ColumnType[];
};

export const TableGridHeader = styled.h2`
  margin-bottom: 1.25rem;
`;

export const TableWithGrid = ({ columns, title }: Props) => {
  const theme = useTheme();

  return (
    <Stack width="100%">
      {title ? (
        <TableGridHeader data-cy="title-table">{title}</TableGridHeader>
      ) : null}

      <Grid
        container
        marginBottom={"10px"}
        css={css`
          border: 2px solid ${theme.colors.gray[90]};
          border-bottom-width: 1px;
          overflow: hidden;
        `}
      >
        {columns.map((col, index: number) => (
          <Grid
            key={`info_table_col_${col.label}_${index}`}
            item
            md={col.size}
            xs={12}
          >
            <StripedGridItem title={col.label} css={col.columnCss}>
              {col.element}
            </StripedGridItem>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
