import '@/data/server-state/api-clients/memberyoApiClient';

import { datadogRum } from '@datadog/browser-rum-slim';
import { Global, ThemeProvider } from '@emotion/react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { globalStyles, useTheme } from 'ui';

import { AppContainer } from '@/components/pages-components/app-container/appContainer';
import { store } from '@/data/client-state/store';
import { queryClient } from '@/utils/apiClientError';
import { environmentChecker } from '@/utils/environmentChecker';

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function installDataDogRUM() {
  if (!environmentChecker.isProduction() || typeof window === 'undefined') {
    return;
  }

  const appId = process.env.NEXT_PUBLIC_DD_APP_ID;
  const clientToken = process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN_MEMBERYO_ADMIN_WEB;

  if (!appId || !clientToken) {
    return;
  }

  datadogRum.init({
    applicationId: appId,
    clientToken: clientToken,
    site: 'datadoghq.com',
    service: 'memberyo-admin-web',
    env: 'prd',
    version: process.env.NEXT_PUBLIC_GIT_COMMIT_SHA,
    sessionSampleRate: 20,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

if (environmentChecker.isDevelopment() && typeof window !== 'undefined') {
  import('../mocks/browser').then(({ worker }) => {
    worker?.start();
  });
}

installDataDogRUM();

export default function App(props: AppPropsWithLayout) {
  const theme = useTheme({ colorMode: 'auto' });
  const muiTheme = createTheme({});

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <AppContainer {...props} />
          </MuiThemeProvider>
        </ThemeProvider>
        <Global styles={globalStyles} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}
