export const AUTHENTICATION_TOAST_MESSAGES = {
  loginErrorOccurred: '로그인중 에러가 발생했습니다.',
  idOrPasswordWrong: 'ID/PW 정보를 다시 확인해 주세요.',
  passwordGenerationError: '비밀번호 생성 오류!',
  forbiddenAccessError: '권한이 있는 관리자만 접근 가능합니다.',
};
export const MEMBER_TOAST_MESSAGES = {
  resetCouponCertificationSuccess: '인증 횟수가 초기화 되었습니다.',
  resetPhoneVerificationSuccess: '휴대폰 인증 횟수가 초기화 되었습니다.',
};
export const BLOCK_TOAST_MESSAGES = {
  duplicatedBlockError: '이미 등록된 블랙리스트 정보입니다.',
  invalidBlockContent: '특수문자, 공백만 입력할 수 없습니다.',
};
export const COMMON_TOAST_MESSAGES = {
  updateSuccess: '저장이 완료되었습니다.',
  deleteSuccess: '삭제 되었습니다.',
  pleaseTryAgain: '잠시 후 다시 시도해 주세요.',
  serverError: '서버 오류',
  apiNotFound: 'API를 찾을 수 없음',
  gatewayTimeout: '[오류 발생] 잠시 후 다시 시도해 주세요',
  notAcceptedFile: '파일 양식을 다시 확인해 주세요.',
  fileNotExist: '파일을 첨부해 주세요.',
  unknown: 'Unknown',
};
