import styled from '@emotion/styled';
import { WrapperProps } from '../Container';
import {
  ANIMATION_DURATION,
  BOTTOMSHEET_MARGION_TOP,
  BOTTOMSHEET_PADDING,
  BOUND_GAP,
} from './constants';
import { UnderlayProps } from './type';
import { css } from '@emotion/react';

export const Underlay = styled.div<UnderlayProps>`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  z-index: ${({ zIndex }) => zIndex};

  width: 100%;
  height: 100%;

  ${({ isOpen }) =>
    isOpen === false
      ? css`
          opacity: 0;
          transition: ${ANIMATION_DURATION}ms;
        `
      : css`
          opacity: 1;
        `}

  ${({ show }) =>
    show === false
      ? css`
          visibility: hidden;
        `
      : ''}

  background-color: ${({ theme }) => theme.colors.dimBg};
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  position: fixed;
  bottom: 0;

  width: 100%;
  max-height: ${`calc(100% - ${BOTTOMSHEET_MARGION_TOP - BOUND_GAP}px)`};

  transform: ${({ isOpen }) => {
    return `translateY(${isOpen ? `${BOUND_GAP}px` : '100%'})`;
  }};
  transition: transform ${ANIMATION_DURATION}ms cubic-bezier(0, 1.15, 0.4, 1.01);

  border-radius: 16px 16px 0px 0px;

  ${({ theme }) =>
    css`
      background: ${theme.colors.topBg};
    `}

  ${({ hasTitle }) => !hasTitle && `padding-top: ${BOTTOMSHEET_PADDING}px`};
`;
