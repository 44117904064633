import { ReactElement } from 'react';
import { BOUND_GAP } from './common/constants';
import { FooterProps } from './common/type';
import styled from '@emotion/styled';
import { Button } from '@mui/material';

const StyledButton = styled(Button)``;

const Container = styled.div`
  display: flex;
  justify-content: center;

  flex: 0 0 auto;

  padding: 0 16px ${32 + BOUND_GAP}px;

  ${StyledButton} {
    flex-grow: 1;
  }

  ${StyledButton} + ${StyledButton} {
    margin-left: 8px;
  }
`;

export function Footer({ buttons }: FooterProps): ReactElement {
  return (
    <Container>
      {buttons.map((prop, index) => (
        <StyledButton key={`${index}_${prop.children}`} {...prop} />
      ))}
    </Container>
  );
}
