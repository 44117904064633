import { TableRow } from "@mui/material";
import React from "react";
import { TableListCell } from "./BaseTableParts";

type EmptyRowType = {
  colSpan?: number;
  height?: number | string;
};
export default function EmptyRow({ colSpan = 1, height = 200 }: EmptyRowType) {
  return (
    <TableRow data-cy="empty-row">
      <TableListCell colSpan={colSpan} height={height}>
        검색 결과가 없습니다.
      </TableListCell>
    </TableRow>
  );
}
