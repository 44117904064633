export const ANIMATION_DURATION = 500;

export const EXTENSION_BAR_HEIGHT = 24;
const HEADER_HEIGHT = 64;
const BODY_BOTTOM_MARGIN = 24;
export const FOOTER_BOTTOM_LAYOUT = 48;
export const FOOTER_BOTTOM_MARGIN = 32;

export const REMAIN_HEIGHT = EXTENSION_BAR_HEIGHT + HEADER_HEIGHT + BODY_BOTTOM_MARGIN;

export const HALF_HEIGHT = `calc(50vh - ${REMAIN_HEIGHT}px)`;

export const BOTTOMSHEET_MARGION_TOP = 32;
export const BOTTOMSHEET_PADDING = 24;
export const BOUND_GAP = 12;
