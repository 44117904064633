export const HOME_PATH = '/';

export const MEMBER_MANAGEMENT_INQUIRY = '/member-management/inquiry';

export const MEMBER_DETAIL = '/member-management/inquiry/:id';

export const MEMBER_MANAGEMENT_AUTHENTICATION_RESET = '/member-management/authentication-reset';

export const BLOCK_MANAGEMENT = '/block-management';

export const KISA_MANAGEMENT = '/kisa-management';

export const FORBIDDEN_PAGE = '/403';

export const ADMIN_PORTAL_URL = process.env.NEXT_PUBLIC_ADMIN_PORTAL_URL;
export const ADMIN_PORTAL_LOGOUT_URL = `${ADMIN_PORTAL_URL}/logout`;

export const PUBLIC_PATHS: string[] = [FORBIDDEN_PAGE];
