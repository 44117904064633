import { useState } from "react";

export type CheckboxData = {
  name: string;
  value: string;
  checked: boolean;
};

export const useCheckboxList = (initialCheckboxList: CheckboxData[]) => {
  const [checkboxList, setCheckboxList] =
    useState<CheckboxData[]>(initialCheckboxList);

  const handleCheckboxChange = (checkbox: CheckboxData) => {
    setCheckboxList((prevCheckboxList) => {
      return prevCheckboxList.map((item) => {
        if (item.name === checkbox.name) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
    });
  };

  return { checkboxList, handleCheckboxChange };
};
