import { QueryClient } from 'react-query';

import { DIALOG_CONTENTS } from '@/constants/dialogContents';
import { AUTHENTICATION_TOAST_MESSAGES, COMMON_TOAST_MESSAGES } from '@/constants/toastMessages';
import { dialogSlice } from '@/data/client-state/slices/ui/dialogSlice';
import { toastSlice } from '@/data/client-state/slices/ui/toastSlice';
import { store } from '@/data/client-state/store';
import { AxiosResponseError } from '@/data/server-state/types/error';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => {
        handleToastError(error as AxiosResponseError<unknown>);
      },
    },
    mutations: {
      onError: (error) => {
        handleToastError(error as AxiosResponseError<unknown>);
      },
    },
  },
});

export const handleToastError = (error: AxiosResponseError<unknown>) => {
  const errorMessage = error?.data?.error_message;
  if (errorMessage) {
    store.dispatch(toastSlice.actions.show({ message: errorMessage, type: 'warning' }));
    return;
  }
  switch (error?.status) {
    case 504:
      store.dispatch(
        toastSlice.actions.show({ message: COMMON_TOAST_MESSAGES.gatewayTimeout, type: 'warning' })
      );
      break;
    case 500:
      store.dispatch(
        toastSlice.actions.show({ message: COMMON_TOAST_MESSAGES.serverError, type: 'warning' })
      );
      break;
    case 404:
      store.dispatch(
        toastSlice.actions.show({ message: COMMON_TOAST_MESSAGES.apiNotFound, type: 'warning' })
      );
      break;
    case 403:
      store.dispatch(
        toastSlice.actions.show({
          message: AUTHENTICATION_TOAST_MESSAGES.forbiddenAccessError,
          type: 'warning',
        })
      );
      break;
    case 401:
      store.dispatch(dialogSlice.actions.showUnauthenticatedDialog(DIALOG_CONTENTS.unauthorized));
      break;
    default:
      break;
  }
};
