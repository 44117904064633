import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

export const useOutsideClick = (
  refs: RefObject<Element>[],
  onOutSideClick?: (event: Event) => void
): void => {
  useEffect(() => {
    const handleOutSideClick = (event: Event) => {
      const isAllOutsideClicked = refs.every((ref) => {
        return event.target instanceof Node && ref.current && !ref.current.contains(event.target);
      });
      if (isAllOutsideClicked) {
        onOutSideClick && onOutSideClick(event);
      }
    };

    document.addEventListener('mousedown', handleOutSideClick);
    document.addEventListener('touchstart', handleOutSideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutSideClick);
      document.removeEventListener('touchstart', handleOutSideClick);
    };
  }, [refs, onOutSideClick]);
};
