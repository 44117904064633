import {
  FOOTER_BOTTOM_LAYOUT,
  FOOTER_BOTTOM_MARGIN,
  EXTENSION_BAR_HEIGHT,
  BOUND_GAP,
} from './constants';

type SubtractBottomSheetHeightProps = {
  hasButtons: boolean;
  hasExtensionBar: boolean;
  remainHeight: number;
};

export const subtractBottomSheetHeight = ({
  hasButtons,
  hasExtensionBar,
  remainHeight,
}: SubtractBottomSheetHeightProps) => {
  if (hasButtons) {
    remainHeight += FOOTER_BOTTOM_LAYOUT + FOOTER_BOTTOM_MARGIN;
  }
  if (hasExtensionBar) {
    remainHeight += EXTENSION_BAR_HEIGHT;
  }

  return remainHeight - BOUND_GAP;
};
