import {
  FieldValues,
  Path,
  UseFormRegister,
  useFormContext,
} from "react-hook-form";
import { Option, SelectBox, SelectBoxOption, SelectOption } from "ui";

type SelectProps<TFormRegister extends FieldValues> = {
  width?: string;
  name: Path<TFormRegister>;
  register: UseFormRegister<TFormRegister>;
  handleChange?: (value: SelectOption) => void;
  options: SelectOption[];
  defaultValue?: string;
  multiple?: boolean;
  disabled?: boolean;
};

export const Select = <TFormRegister extends FieldValues>({
  register,
  handleChange,
  name,
  options,
  disabled = false,
  ...rest
}: SelectProps<TFormRegister>) => {
  const formContext = useFormContext();
  const registerSelect = register(name);

  return (
    <div>
      <SelectBox
        {...registerSelect}
        onChange={({ selected, ...rest }: SelectBoxOption) => {
          const event = { target: { ...rest, name } };

          registerSelect?.onChange(event);
          handleChange?.(rest);
        }}
        disabled={disabled}
        {...rest}
      >
        {options.map((optionData) => {
          return (
            <Option
              key={`select_${name}_${optionData.value}`}
              name={optionData.name}
              value={optionData.value}
              selected={optionData.value === formContext?.watch?.(name)}
            />
          );
        })}
      </SelectBox>
    </div>
  );
};
