import styled from "@emotion/styled";
import { MaskedTextStyledProps, TextWithInputStyledProps } from "./type";
import { css } from "@emotion/react";

export const StyledText = styled.div`
  width: 100%;
`;

export const MaskedText = styled(StyledText)<MaskedTextStyledProps>`
  text-decoration: ${({ isVisible }) => (isVisible ? "none" : "underline")};
  color: ${({ theme }) => theme.colors.blue["90"]};
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? css`
          color: black;
          cursor: unset;
          text-decoration: none;
        `
      : ""};
`;

export const StyledTextWithEllipsis = styled(
  StyledText
)<TextWithInputStyledProps>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (
    -webkit-line-clamp: ${({ textEllipsisLine }) => textEllipsisLine}
  ) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${({ textEllipsisLine }) => textEllipsisLine};
    -webkit-box-orient: vertical;
  }
`;
