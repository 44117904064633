import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface TableBlockProps {
  scroll?: boolean;
  col_2?: boolean;
}

export const TableBlock = styled.div<TableBlockProps>`
  box-sizing: border-box;
  border: 1px solid #999;
  background-color: #fff;
  word-break: break-all;
  width: 100%;
  scroll-behavior: smooth;

  ${(props) =>
    props.scroll &&
    `
      max-height: 380px;
      overflow: auto;
    `}
  ${(props) =>
    props.col_2 &&
    `
      display: table;
    `}
  max-height: 380px;
  overflow: auto;
`;

interface TableWrapProps {
  width?: string;
}

export const TableWrap = styled.div<TableWrapProps>`
  display: table-cell;
  width: ${(props) => (props.width ? props.width : "100%")};
  &:first-of-type {
    border-right: 1px solid #999;
  }
`;

export const Table = styled.table`
  width: 100%;
  background: #fff;
  tr:first-of-type th,
  tr:first-of-type td {
    border-top: unset;
  }
`;

interface TableListProps {
  width?: string;
  center?: boolean;
  selectlist?: boolean;
  type_2?: boolean;
  fixed?: boolean;
}

export const TableList = styled.table<TableListProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
  background: #fff;
  table-layout: ${(props) => (props.fixed ? "fixed" : "auto")};

  thead tr:first-of-type th {
    border-top: unset;
  }

  tfoot th {
    position: sticky;
    bottom: 0;
  }
  tfoot {
    ${(props) => props.hidden && "display: none"};
  }
  ${(props) => props.center && "text-align: center"};
  ${(props) =>
    props.selectlist &&
    `
      ${TableRow} {
        cursor: pointer;
      }
    `}
  ${(props) =>
    props.type_2 &&
    `
      ${TableListCellTitle} {
        height: 32px;
      }
    `}
`;

interface TableRowProps {
  disabled?: boolean;
  selected?: boolean;
  activated?: boolean;
  in_use?: boolean;
  sub?: boolean;
  onClick?: () => void;
}

export const TableRow = styled.tr<TableRowProps>`
  th:first-of-type,
  td:first-of-type {
    border-left: unset;
  }
  ${(props) => props.selected && "background: #e6f2ff"};
  ${(props) => props.activated && "background: #e6f4f2"};
  ${(props) => props.in_use && "font-weight: bold"};
`;

const CellTitle = css`
  box-sizing: border-box;
  background-color: #ebebeb;
  border-top: 1px solid #d9d9d9;
  height: 45px;
  color: #212121;
  font-size: 12px;
`;

export const TableListCellTitle = styled.th`
  ${CellTitle}
  border-left: 1px solid #d9d9d9;
  word-break: keep-all;
  white-space: pre-wrap;
`;

interface TableListCellProps {
  no_content?: boolean;
  is_link?: boolean;
}

interface CellProps {
  left?: boolean;
  right?: boolean;
  sub?: number;
  bdr?: boolean;
}

const Cell = css`
  box-sizing: border-box;
  border-top: 1px solid #d9d9d9;
  color: #212121;
  font-size: 12px;
`;

export const TableCellTitle = styled.th`
  ${CellTitle}
  width: 149px;
  padding: 0 10px;
  text-align: left;
  i {
    color: #f00000;
  }
`;

interface TableCellProps {
  col_2?: boolean;
}

export const TableCell = styled.td<CellProps & TableCellProps>`
  ${Cell}
  width: ${(props) => props.col_2 && "360px"};
  height: 43px;
  padding: 9px 10px;
`;

export const TableListCell = styled.td<CellProps & TableListCellProps>`
  ${Cell}
  text-align: center;
  padding: 6px 10px;
  border-left: 1px solid #d9d9d9;
  white-space: pre-wrap;
  line-height: 1.4;
  word-break: break-word;
  ${(props) =>
    props.no_content &&
    css`
      height: 326px;
      color: #999;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    `}
  ${(props) =>
    props.is_link &&
    css`
      cursor: pointer;
      text-decoration: underline;
    `}
`;

export const TableAddList = styled.table`
  width: 100%;
  border: 1px solid #ccc;
`;

export const TableAddRow = styled.tr``;

export const TableAddCellTitle = styled.th`
  ${CellTitle}
  height: 36px;
  border: 1px solid #ccc;
  background-color: #e6e6e6;
`;

export const TableAddCell = styled.td`
  ${Cell}
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 0 10px;
`;

export const HelperText = styled.span`
  display: inline-block;
  color: #999;
  font-size: 11px;
  margin: 5px 0 0 10px;
  &.auth {
    color: #02bd5d;
  }
  &.error {
    color: #f00000;
  }
`;

export const AddressError = styled.div`
  padding-top: 4px;
  color: #ff0000;
  font-size: 11px;
`;

interface TextProps {
  bar?: boolean;
  notice?: boolean;
  width?: number;
  small?: boolean;
  disabled?: boolean;
  is_link?: boolean;
  desc?: boolean;
}

export const Text = styled.span<TextProps>`
  font-size: 12px;
  margin: 4px 4px 0 0;
  label:last-child {
    margin-right: 0;
  }
  ${(props) =>
    props.bar &&
    css`
      display: inline-block;
      width: 1px;
      height: 16px;
      background: #d9d9d9;
      margin: 4px 8px 0 8px;
      text-indent: -999px;
    `}
  ${(props) =>
    props.title &&
    css`
      &:before {
        content: "‧ ";
      }
      font-weight: bold;
    `}
  ${(props) =>
    props.notice &&
    css`
      font-size: 11px;
      color: #f00000;
    `}
  ${(props) => props.width && `min-width: ${props.width}px;`}
  ${(props) => props.small && "color: #999;font-size: 11px;"}
  ${(props) => props.disabled && "color: #ccc;"}
  ${(props) =>
    props.is_link && "text-decoration: underline; cursor: pointer; width:100%"}
  ${(props) => props.desc && "color: #5F6367;"}
`;
