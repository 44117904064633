import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  SelectHTMLAttributes,
  useRef,
} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "@emotion/styled";
import mergeRefs from "../../../utils/mergeRefs";

const SelectBoxBlock = styled.div<Pick<SelectBoxProps, "width" | "disabled">>`
  position: relative;
  width: ${({ width }) => width}px;
  color: ${({ disabled }) => disabled && "#ccc"};
`;

const Select = styled.select<Pick<SelectBoxProps, "error">>`
  width: 100%;
  height: 24px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid ${({ error }) => (error ? "#f00000" : "#ccc")};
  padding: 0 20px 0 10px;
  color: #212121;
  font-size: 12px;
  &:focus {
    border-color: #595959;
  }
  &:disabled {
    pointer-events: none;
    color: #ccc;
    border-color: #ccc;
    background-color: #f5f5f5;
  }
`;

const DropDownIcon = styled(KeyboardArrowDownIcon)`
  position: absolute;
  top: 3px;
  right: 2px;
  font-size: 18px !important;
  pointer-events: none;
`;

type NativeSelectPropsToOmit = "onChange" | "width" | "style";
type NativeSelectProps = SelectHTMLAttributes<HTMLSelectElement>;

type SelectBoxProps = Omit<NativeSelectProps, NativeSelectPropsToOmit> & {
  onChange: (selectedOption: SelectBoxOption) => void;
  width?: string;
  error?: boolean;
  style?: CSSProperties;
};

const InnerSelectBox = (
  { width = "140", disabled, children, ...props }: SelectBoxProps,
  outerRef: ForwardedRef<HTMLSelectElement>
) => {
  const innerRef = useRef<HTMLSelectElement>(null);
  const baseRef = mergeRefs(outerRef, innerRef);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.selectedOptions[0];

    props.onChange({
      name: selectedOption.text,
      value: selectedOption.value,
      selected: selectedOption.selected,
    });
  };

  return (
    <SelectBoxBlock width={width} disabled={disabled}>
      <Select
        {...props}
        disabled={disabled}
        ref={baseRef}
        onChange={handleChange}
      >
        {children}
      </Select>
      <DropDownIcon />
    </SelectBoxBlock>
  );
};

export const SelectBox = forwardRef(InnerSelectBox);

export type SelectBoxOption = {
  name: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
};

export type SelectOption = Omit<SelectBoxOption, "selected">;

export const Option = (props: SelectBoxOption) => {
  return (
    <option
      key={props.value}
      value={props.value}
      selected={props.selected}
      disabled={props.disabled}
    >
      {props.name}
    </option>
  );
};
