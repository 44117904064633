import styled from '@emotion/styled';
import axios from 'axios';
import { saveAs } from 'file-saver';
import React, { FC } from 'react';

type Props = {
  href?: string;
  fileName?: string;
  withToken?: boolean;
  children: React.ReactNode;
};

export const DownloadableLink: FC<Props> = ({ href, fileName, withToken, children }) => {
  const handleOnClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      if (!href) {
        throw Error('다운로드 링크가 존재하지 않습니다.');
      }

      const pathname = new URL(href).pathname;
      const filename =
        fileName ??
        pathname
          .split('/')
          .filter((value) => !!value)
          .pop();

      if (!filename) {
        throw Error('다운로드 링크에서 다운로드 가능한 파일을 찾을 수 없습니다.');
      }

      if (!withToken) {
        saveAs(href, filename);
        return;
      }

      const { data: file } = await axios.get(href, { responseType: 'blob' });
      saveAs(file, filename);
    } catch (e: any) {
      const error: Error | TypeError = e;

      if (error instanceof TypeError) {
        // NOTE: This case href is broken url
        alert('파일을 다운로드 할 수 없습니다.');
        return void 0;
      }

      if (e.response?.status === 403) {
        alert('파일을 다운로드 할 수 없습니다. 파일이 존재하지 않거나, 권한이 없습니다.');
        return void 0;
      }

      alert(e.message);
    }
  };

  return <Link onClick={handleOnClick}>{children}</Link>;
};

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: blue;
`;
