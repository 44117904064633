import { Tooltip } from "@mui/material";
import { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { StyledText, StyledTextWithEllipsis } from "./common/styled";
import { TextWithTooltipProps } from "./common/type";

export const TextWithTooltip = ({
  title,
  textEllipsisLine = 1,
  textEllipsis = true,
  tooltipValue,
  className,
  style,
  tooltipType = "hover",
  onClick,
}: TextWithTooltipProps) => {
  const [open, setOpen] = useState(false);

  const Text = textEllipsis ? StyledTextWithEllipsis : StyledText;
  const tooltipTitle = tooltipValue ?? (textEllipsis ? title : "");

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const getTooltipsProps = () => {
    if (tooltipType === "click") {
      return {
        open,
        disableFocusListener: true,
        disableHoverListener: true,
        disableTouchListener: true,
        onClose: handleTooltipClose,
      };
    }
  };

  const handlerClick = () => {
    handleTooltipOpen();
    onClick?.(tooltipTitle);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip title={tooltipTitle} arrow {...getTooltipsProps()}>
        <Text
          style={style}
          className={className}
          textEllipsisLine={textEllipsisLine}
          onClick={handlerClick}
          onBlur={handleTooltipClose}
        >
          {title}
        </Text>
      </Tooltip>
    </ClickAwayListener>
  );
};
