import React from "react";

import { Button } from "../../atoms/Button";
import styled from "@emotion/styled";

type User = {
  name: string;
};

interface HeaderProps {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  border-bottom: 0.5px solid #a8a8a8;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0%;
  width: 100vw;
  z-index: 50;
  background-color: #fff;
`;

const Logo = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
  color: #fd184f;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
`;

const Welcome = styled.span`
  color: #333;
  font-size: 14px;
  margin-right: 10px;
`;

const CustomButton = styled(Button)`
  font-size: 12px;
`;

export const Header = ({ children }: HeaderProps) => (
  <Wrapper>{children}</Wrapper>
);
