import { CSSProperties } from "react";
import { UseFormRegister } from "react-hook-form";
import { TextField } from "../atoms";

type TextFieldUserIDProps = {
  register: UserIdFormRegister;
  value?: string;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  style?: CSSProperties;
  onChange?: (val: string) => void;
};

export function TextFieldUserID({
  register,
  width = "100%",
  placeholder = "아이디",
  disabled = false,
  error,
  style,
  ...props
}: TextFieldUserIDProps) {
  return (
    <TextField
      id="user-id"
      type="text"
      width={width}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      {...props}
      {...register("userId")}
      style={style}
    />
  );
}

type UserIdFormRegister = UseFormRegister<{
  userId: string;
}>;
