import { useState } from 'react';

export const useDatePicker = () => {
  const [date, setDate] = useState(new Date());
  const onChange = (date: Date) => {
    setDate(date);
  };

  return { date, setDate, onChange };
};
