import { useState } from "react";
import { MaskedText as MaskedTextWithStyle } from "./common/styled";
import { MaskedTextProps } from "./common/type";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export const MaskedText = ({
  title,
  getMaskedText,
  onClick: onClickMaskedText,
  disabled,
  ...restProps
}: MaskedTextProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const maskedText = getMaskedText(title);
  const currentText = isVisible ? title : maskedText;

  const handlerClickText = () => {
    if (disabled) {
      return;
    }

    setIsVisible((preVisibleState) => {
      if (!preVisibleState) {
        onClickMaskedText?.(title);
      }
      return !preVisibleState;
    });
  };

  const handlerMaskedText = () => {
    setIsVisible(false);
  };

  return (
    <ClickAwayListener onClickAway={handlerMaskedText}>
      <MaskedTextWithStyle
        isVisible={isVisible}
        disabled={disabled}
        {...restProps}
        onClick={handlerClickText}
      >
        {currentText}
      </MaskedTextWithStyle>
    </ClickAwayListener>
  );
};
