import { SystemMenu } from '../types/systemMenu';
import memberyoApiClient from './memberyoApiClient';

const getSystemMenusName = (menuData: SystemMenu[]) => {
  let systemMenusName: string[] = [];
  menuData.forEach((item) => {
    systemMenusName.push(item.menu_name);
    if (item.children) {
      systemMenusName = systemMenusName.concat(getSystemMenusName(item.children));
    }
  });
  return systemMenusName;
};

export const getSystemMenus = (): Promise<string[]> =>
  memberyoApiClient
    .get<SystemMenu[]>('/v1/menus')
    .then((response) => getSystemMenusName(response.data));
