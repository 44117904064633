const koreanWonFormatter = new Intl.NumberFormat("ko-KR", {
  maximumFractionDigits: 0,
});

export const numberToKoreanWon = (value: number): string =>
  koreanWonFormatter.format(Math.floor(value));

const maskDashSeparatedPhoneNumber = (phoneNumber: string) => {
  const VALID_PHONE_NUMBER_ARR_LENGTH = 3;
  const MIDDLE_PHONE_NUMBER_INDEX = 1;

  const dashSeparatedPhoneNumber = phoneNumber.split("-");
  if (dashSeparatedPhoneNumber.length !== VALID_PHONE_NUMBER_ARR_LENGTH) {
    return "Invalid phone number";
  }
  dashSeparatedPhoneNumber[MIDDLE_PHONE_NUMBER_INDEX] = "*".repeat(
    dashSeparatedPhoneNumber[MIDDLE_PHONE_NUMBER_INDEX].length
  );
  return dashSeparatedPhoneNumber.join("-");
};

const maskPhoneNumber = (phoneNumber: string) => {
  const MOBILE_PHONE_NUMBER_LENGTH_OF_011_FORMAT = 10;

  if (phoneNumber.length === MOBILE_PHONE_NUMBER_LENGTH_OF_011_FORMAT) {
    return phoneNumber.replace(/^(\d{3})(\d{3})(\d+)/, "$1***$3");
  }

  return phoneNumber.replace(/^(\d{3})(\d{4})(\d+)/, "$1****$3");
};

export const getMaskedPhoneNumber = (rawPhoneNumber: string) => {
  if (rawPhoneNumber.includes("-")) {
    return maskDashSeparatedPhoneNumber(rawPhoneNumber);
  } else {
    return maskPhoneNumber(rawPhoneNumber);
  }
};

export const getMaskedDetailAddress = (address?: string) => {
  if (!address?.length) return "";

  const commasSeparatedAddress = address.split(",");

  commasSeparatedAddress.pop();

  return commasSeparatedAddress.join("").concat(" ***");
};
