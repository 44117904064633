import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastType } from 'ui';

export type ToastState = {
  display: boolean;
  message: string;
  type?: ToastType;
};

const initialState: ToastState = {
  display: false,
  message: '',
  type: undefined,
};

export const toastSlice = createSlice({
  name: 'toastSlice',
  initialState,
  reducers: {
    show: (_, action: PayloadAction<Omit<ToastState, 'display'>>) => ({
      display: true,
      message: action.payload?.message,
      type: action.payload?.type || undefined,
    }),
    hide: () => ({
      display: false,
      message: '',
      type: undefined,
    }),
  },
});

export const toastReducer = toastSlice.reducer;

export const { show, hide } = toastSlice.actions;
