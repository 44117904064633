import styled from '@emotion/styled';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import {
  getMenu,
  getMenuByPath,
  MenuListType,
} from '@/components/common-components/layouts/side-navigation-bar/menu-url-mapper';
import Navigation from '@/components/common-components/layouts/side-navigation-bar/SideNavigationBar';
import { USER_ROLE } from '@/constants/cookieName';

export const initialOpenedMenuState: string[] = ['회원 관리', '블랙리스트 관리', '탈퇴 요청 관리'];

type MenuName = Pick<MenuListType, 'name'>;

const openMenu = (state: string[], { name }: MenuName) => state.concat([name]);

const closeMenu = (state: string[], { name }: MenuName) =>
  state.filter((opened: string) => opened !== name);

const toggleMenu = (state: string[], { name }: MenuName) =>
  (state.includes(name) ? closeMenu : openMenu)(state, { name });

type MenuAction = typeof openMenu | typeof closeMenu | typeof toggleMenu;

interface MenuOpenedStateReturnType {
  openedMenuState: string[];
  toggle: (menu: MenuListType[]) => void;
  open: (menu: MenuListType[]) => void;
  close: (menu: MenuListType[]) => void;
}

const useMenuOpenedState = (initialState: string[]): MenuOpenedStateReturnType => {
  const [menuStatusMap, setMenuStatusMap] = useState(initialState);

  const setManyState = (fn: MenuAction) => (menu: MenuListType[]) =>
    setMenuStatusMap(menu.reduce(fn, menuStatusMap));

  return {
    openedMenuState: menuStatusMap,
    toggle: setManyState(toggleMenu),
    open: setManyState(openMenu),
    close: setManyState(closeMenu),
  };
};

interface NavigationContainerProps {
  show: boolean;
}

type StringBooleanMap = {
  [key: string]: boolean;
};

export const createMenuObject = (menuName: string | undefined) => {
  if (!menuName) {
    return {};
  }
  const menuNames = menuName.split(',');
  const menuObject: StringBooleanMap = {};
  for (const menu of menuNames) {
    menuObject[menu] = true;
  }
  return menuObject;
};

export const SideNavigationBarContainer = ({ show }: NavigationContainerProps): JSX.Element => {
  const router = useRouter();
  const selectedMenu = getMenuByPath(router.pathname);
  const userRoleStorage = Cookies.get(USER_ROLE);

  const menu: MenuListType[] = useMemo(
    () => getMenu(createMenuObject(userRoleStorage)),
    [userRoleStorage]
  );

  const { openedMenuState, toggle, open, close } = useMenuOpenedState(initialOpenedMenuState);

  const handleClickOnMenu = (name: string) => {
    const menuItem = menu.find((item) => item.name === name);
    if (menuItem?.path) {
      router.push(menuItem?.path);
      return;
    }
  };

  return (
    <CustomNavigation>
      <Navigation
        show={show}
        menu={menu}
        openedMenuState={openedMenuState}
        selectedMenu={selectedMenu}
        toggle={toggle}
        open={open}
        close={close}
        handleClickOnMenu={handleClickOnMenu}
      />
    </CustomNavigation>
  );
};

const CustomNavigation = styled.div`
  & > div {
    width: 196px;
  }
`;
