import styled from "@emotion/styled";
import {
  Checkbox,
  CheckboxProps as MuiCheckboxProps,
  Stack,
} from "@mui/material";
import { ChangeEventHandler, CSSProperties, FC } from "react";

const StyledCheckBox = styled(Checkbox)`
  cursor: pointer;
  color: #212121;
  font-size: 12px;
  ${(props) => props.disabled && "color: #828282; pointer-events: none;"};
`;

export interface CheckBoxProps extends MuiCheckboxProps {
  name: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  disabled?: boolean;
  value?: string | number;
  defaultChecked?: boolean;
  style?: CSSProperties;
}

const Label = styled.span`
  font-size: 8px;
  color: #212121;
  cursor: pointer;
  white-space: nowrap;
`;

const DisabledLabel = styled(Label)`
  color: #828282;
  pointer-events: none;
`;

export const CheckBox: FC<CheckBoxProps> = (props) => {
  const CheckBoxLabel = props.disabled ? DisabledLabel : Label;

  return props.label ? (
    <Stack direction="row" alignItems={"center"}>
      <StyledCheckBox
        {...props}
        checked={props.checked}
        onChange={props.onChange}
        name={props.name}
        disabled={props.disabled}
        value={props.value}
        defaultChecked={props.defaultChecked}
        style={props.style}
      />
      <CheckBoxLabel>{props.label}</CheckBoxLabel>
    </Stack>
  ) : (
    <StyledCheckBox
      {...props}
      checked={props.checked}
      onChange={props.onChange}
      name={props.name}
      disabled={props.disabled}
      value={props.value}
      defaultChecked={props.defaultChecked}
      style={props.style}
    />
  );
};
