import { ChangeEvent, Ref, forwardRef } from "react";
import { replace } from "ramda";
import { UseFormRegister } from "react-hook-form";
import { TextField } from "../atoms";
import mergeRefs from "../../utils/mergeRefs";
import { Types, getFormattedValue } from "ui/utils/utilsFormatter";

interface FormattedFieldProps {
  register?: FormRegisterNumber;
  registerName?: string;
  type: Types | "number" | "string";
  value?: string;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onChange?: (val: string) => void;
  autoFocus?: boolean;
}

const FormattedFieldNumber = (
  {
    type,
    value,
    width = "140",
    placeholder = "번호만 입력",
    disabled = false,
    onChange,
    register,
    registerName,
    autoFocus,
    error,
    ...props
  }: FormattedFieldProps,
  textFieldRef: Ref<HTMLInputElement>
) => {
  const formatInputValue = (value: string) => {
    if (type === "string") {
      return value;
    }
    if (type === "number") {
      return replace(/[^0-9]/g, "")(value);
    }
    return getFormattedValue(type, value);
  };
  if (register && registerName) {
    const { ref, ...registerInput } = register(registerName);
    const baseRef = mergeRefs(ref, textFieldRef);
    return (
      <TextField
        data-cy={registerName}
        type="text"
        width={width}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        autoFocus={autoFocus}
        ref={baseRef}
        {...registerInput}
        error={error}
        {...props}
        onChange={(e) => {
          const val = formatInputValue(e.target.value);
          registerInput.onChange({
            type: "change",
            target: {
              name: registerInput.name,
              value: val,
            },
          });
        }}
      />
    );
  }
  return (
    <TextField
      type="text"
      width={width}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      autoFocus={autoFocus}
      ref={textFieldRef}
      {...props}
      onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const val = formatInputValue(value);
        onChange?.(val);
      }}
    />
  );
};

type FormRegisterNumber = UseFormRegister<Record<string, any>>;

export const FormattedField = forwardRef(FormattedFieldNumber);
