import { css } from '@emotion/react';

const resetStyles = css`
  * {
    margin: 0;
    padding: 0;
    font-family: '맑은 고딕', 'Malgun Gothic', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a,
  body,
  button,
  dd,
  div,
  dl,
  dt,
  fieldset,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  legend,
  li,
  ol,
  p,
  select,
  table,
  td,
  textarea,
  th,
  ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  html {
    font-size: 12px;
  }

  #root:after {
    clear: both;
    content: '';
    display: block;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  ol,
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  textarea:focus,
  input:focus,
  button:focus,
  select:focus {
    outline: none;
  }

  button {
    border: 0;
    background: transparent;
    cursor: pointer;
  }

  input[type]:not([type='checkbox']):not([type='radio']),
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input::-ms-clear {
    display: none;
  }

  select {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
  }
`;

const scrollbarStyles = css`
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    background: #d9d9d9;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #888;
  }
`;

export const globalStyles = css`
  ${resetStyles}

  ${scrollbarStyles}
`;
