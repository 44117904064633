import { useState, MouseEvent, TouchEvent } from 'react';
import { EXTENSION_BAR_HEIGHT } from './common/constants';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  height: ${EXTENSION_BAR_HEIGHT}px;

  cursor: pointer;
`;

const ExtensionBarHanddle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 4px;

  background-color: ${({ theme }) => theme.colors.gray[10]};

  border-radius: 10px;
`;

type ExtensionBarProps = {
  onClose: () => void;
  bottomSheetHeightState: [string, React.Dispatch<React.SetStateAction<string>>];
  defaultHeight: string;
};

const ExtensionBar = ({ onClose, bottomSheetHeightState, defaultHeight }: ExtensionBarProps) => {
  const [touchStartYAxis, setTouchStartYAxis] = useState<number | undefined>(undefined);
  const [height, setHeight] = bottomSheetHeightState;
  const startMove = (touchStart: number) => {
    setTouchStartYAxis(touchStart);
  };

  const endMove = (touchEnd: number) => {
    const dragUp = touchStartYAxis && touchEnd && touchStartYAxis - touchEnd > 0;

    if (dragUp) {
      setHeight('100vh');
    }

    if (!dragUp) {
      setHeight(defaultHeight);
      height === defaultHeight && onClose();
    }
  };
  const handleDragStart = (e: MouseEvent<HTMLDivElement>) => {
    startMove(e.clientY);
  };

  const handleDragEnd = (e: MouseEvent<HTMLDivElement>) => {
    endMove(e.clientY);
  };

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    startMove(e.changedTouches[0].clientY);
  };

  const handleTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
    endMove(e.changedTouches[0].clientY);
  };
  return (
    <Container
      draggable="true"
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <ExtensionBarHanddle />
    </Container>
  );
};

export default ExtensionBar;
