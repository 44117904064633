import styled from "@emotion/styled";
import {
  BlockOutlined as BlockOutlinedIcon,
  DoneOutlined as DoneOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material";
import { ReactNode } from "react";

export type ToastType = "info" | "warning" | "success";

const variants = {
  info: `
    background: #f6f7fa;
    border-color: #66738a;
    color: #4d5971;
  `,
  warning: `
    background: #fff5f5;
    border-color: #eb0800;
    color: #d40700;
  `,
  success: `
    background: #e6f5ed;
    border-color: #1aa35f;
    color: #008a45;
  `,
};

const toastIcons = (variant: ToastType) => {
  switch (variant) {
    case "info":
      return <InfoOutlinedIcon />;
    case "warning":
      return <BlockOutlinedIcon />;
    case "success":
      return <DoneOutlinedIcon />;
    default:
      return;
  }
};
interface ToastProps {
  variant?: ToastType;
}

const ToastBlock = styled.div<ToastProps>`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 40px;
  left: 20px;
  z-index: 1500;
  min-width: 265px;
  height: 56px;
  padding: 0 50px 0 16px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  border-width: 1px;
  border-left-width: 4px;
  border-style: solid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-color: #66738a;
  ${(props) =>
    props.variant
      ? `${variants[props.variant]}`
      : `
          background: #f6f7fa;
          border-color: #66738a;
          color: #4d5971;
        `}
`;

const ToastIconWrap = styled.div`
  svg {
    vertical-align: middle;
    margin-right: 10px;
    font-size: 35px;
  }
`;

export const ToastIcon = ({ children }: { children: ReactNode }) => (
  <ToastIconWrap>{children}</ToastIconWrap>
);

type ToastComponentProps = {
  variant: ToastType | undefined;
  message: string;
};
export function ToastComponent({
  message,
  variant = "info",
}: ToastComponentProps) {
  return (
    <ToastBlock variant={variant} data-cy="toast-block">
      {!!variant && <ToastIcon>{toastIcons(variant)}</ToastIcon>}
      {message}
    </ToastBlock>
  );
}
