import { decode } from 'jsonwebtoken';

import { DIALOG_CONTENTS } from '@/constants/dialogContents';
import { dialogSlice } from '@/data/client-state/slices/ui/dialogSlice';
import { store } from '@/data/client-state/store';
import { clearAuthStorage, handleLogout } from '@/utils/authHandler';
import { getAccessToken } from '@/utils/cookieHandler';

const DEFAULT_TIMEOUT = 30 * 60 * 1000;
let timeoutInstance: undefined | NodeJS.Timeout = undefined;

export function initIdleTimer() {
  if (timeoutInstance) {
    return;
  }
  const jwtToken = getAccessToken();
  if (!jwtToken) {
    return;
  }
  try {
    // Check token expire when visit page
    const decodeJwtToken = decode(jwtToken);
    if (typeof decodeJwtToken !== 'object') {
      handlerTimeOut();
      return;
    }

    const jwtTokenExp = decodeJwtToken?.exp;
    if (!jwtTokenExp) {
      handlerTimeOut();
      return;
    }
    const timestampJwtTokenExpire = jwtTokenExp * 1000;
    const currentTimestamp = Date.now();
    const lifeJwtToken = timestampJwtTokenExpire - currentTimestamp;
    if (lifeJwtToken <= 0) {
      handlerTimeOut();
      return;
    }
    // Init timer with remaining life token
    store.dispatch(dialogSlice.actions.hideUnauthenticatedDialog());
    timeoutInstance = setTimeout(() => {
      handlerTimeOut();
    }, Math.min(lifeJwtToken, DEFAULT_TIMEOUT));
  } catch (error) {
    handleLogout();
    return;
  }
}

export function restartIdleTimer() {
  if (timeoutInstance) {
    clearTimeout(timeoutInstance);
  }

  timeoutInstance = setTimeout(handlerTimeOut, DEFAULT_TIMEOUT);
}

function handlerTimeOut() {
  clearAuthStorage();
  store.dispatch(dialogSlice.actions.showUnauthenticatedDialog(DIALOG_CONTENTS.sessionExpired));
  stopIdleTimer();
}

export function stopIdleTimer() {
  clearTimeout(timeoutInstance);
  timeoutInstance = undefined;
}
