import styled from "@emotion/styled";
import { Breakpoint, Dialog as MDialog, DialogContent } from "@mui/material";
import React, { FC } from "react";
import { CloseButton } from "../atoms/Button";
import { css } from "@emotion/react";

interface DialogProps {
  openDialog: boolean;
  setOpenDialog: (value: React.SetStateAction<boolean>) => void;
  title?: string;
  content?: JSX.Element;
  breakPoint?: Breakpoint;
  width?: number;
  isClickOutsideDisabled?: boolean;
}

const TitleWrapper = styled.div`
  width: 100%;
  height: 62px;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
`;

const Title = styled.h2`
  width: 100%;
  height: 100%;
  padding: 24px 0 0 20px;
  box-sizing: border-box;
  color: #343b46;
  font-size: 20px;
`;

export const BaseDialog: FC<DialogProps> = ({
  openDialog,
  setOpenDialog,
  title,
  content,
  breakPoint = "lg",
  width = 860,
  isClickOutsideDisabled = false,
}) => {
  return (
    <MDialog
      open={openDialog}
      onClose={() => !isClickOutsideDisabled && setOpenDialog(false)}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "unset",
          width: `${width}px`,
        },
      }}
      maxWidth={breakPoint}
    >
      <TitleWrapper>
        <Title>{title}</Title>
        <CloseButton
          css={css`
            position: relative;
            top: 15px;
            right: 10px;
            font-size: 30px;
          `}
          onClick={() => setOpenDialog(false)}
        />
      </TitleWrapper>
      <DialogContent style={{ padding: 0 }}>{content}</DialogContent>
    </MDialog>
  );
};
