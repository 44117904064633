import { useEffect } from 'react';
import { ToastComponent, ToastType } from 'ui';

import { useAppDispatch, useAppSelector } from '@/data/client-state/hooks/useStore';
import { toastSlice } from '@/data/client-state/slices/ui/toastSlice';

interface ToastProps {
  variant?: ToastType;
}

export const Toast = ({ variant }: ToastProps) => {
  const dispatch = useAppDispatch();
  const { display, message, type } = useAppSelector((state) => state.toast);

  const toastVariant = variant || type;

  useEffect(() => {
    if (!display) {
      return undefined;
    }

    const timer = setTimeout(() => dispatch(toastSlice.actions.hide()), 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [display, dispatch]);

  if (!display) {
    return null;
  }

  return <ToastComponent variant={toastVariant} message={message} />;
};
