import React, { FC, ForwardedRef, forwardRef, useRef, useState } from "react";
import * as R from "ramda";

import { UseFormSetValue } from "react-hook-form";

import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";
import { Button, CloseButton } from "../atoms/Button";
import mergeRefs from "../../utils/mergeRefs";
import {
  getFileDownloadURL,
  getFilePath,
  getFilePathWithPermission,
} from "../../utils/filePath";
import { DownloadableLink } from "./FileDownloadLink";

export interface FileType {
  id?: number;
  filename: string;
  file_path: File;
}

const MAX_NUMBER_OF_FILES = 5;

export const ONLY_IMAGE = ".jpg, .jpeg, .bmp, .png, .heic, .gif, .tif, .tiff";
export const ONLY_PNG_JPG = ".png, .jpg, .jpeg";
export const ONLY_IMAGE_MSG =
  "이미지 파일 형식(JPG, JPEG, BMP, PNG, GIF, TIF, TIFF, HEIC)만 저장 가능합니다.";

const UploadButtonBlock = styled.div`
  position: relative;
  flex: 0 0 auto;
  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    opacity: 0;
  }
`;

const FileUploadButton = styled(Button)`
  position: relative;
  z-index: 2;
`;

const Link = styled.a`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const FilesWrapper = styled.div<{ hasPreview?: boolean }>`
  width: 100%;
  height: ${({ hasPreview }) => (hasPreview ? "160px" : "auto")};
  max-height: ${({ hasPreview }) => (hasPreview ? "160px" : "80px")};
  overflow-y: scroll;
  border: 1px solid #cccccc;
  margin-bottom: 8px;
  padding: 8px 10px 0;
  background: #f6f6f6;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #ccc;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #2e86e6;
  }
  .file_close {
    border: 1px solid #cccccc;
    border-radius: 50%;
    margin-left: 6px;
  }
  &.xscroll {
    max-width: 260px;
    overflow-x: scroll;
    white-space: nowrap;
    padding-bottom: 10px;
  }
`;

interface SelectedFilesProps {
  simpleStyle: boolean;
  setValue?: (type: string, files: FileType[]) => void;
  name: string;
  selectedFiles: FileType[];
  hasPreview: boolean;
}
const SelectedFiles: FC<SelectedFilesProps> = ({
  simpleStyle,
  setValue,
  name,
  selectedFiles,
  hasPreview,
}) => {
  const SelectedLabel = <p style={{ margin: "0 10px 0 0" }}>선택된 파일</p>;
  const [imgData, setImgData] = useState<string | ArrayBuffer | null>(null);
  if (hasPreview && selectedFiles && selectedFiles[0]) {
    const reader = new FileReader();
    reader.readAsDataURL(selectedFiles[0].file_path as unknown as Blob);
    reader.onload = function () {
      setImgData(reader.result);
    };
  }

  return (
    <>
      {simpleStyle && selectedFiles.length > 0 && SelectedLabel}
      {selectedFiles.map((file: FileType, idx: number) => (
        <React.Fragment key={`${file.file_path}_${idx}`}>
          <Stack
            marginBottom={5}
            key={`${file.file_path}_${idx}`}
            alignItems={"center"}
          >
            {!simpleStyle && SelectedLabel}
            <Link
              href={getFileDownloadURL(file.file_path, file.file_path.type)}
              download={file.filename}
            >
              {file.filename}
            </Link>
            <CloseButton
              className="file_close"
              onClick={() =>
                setValue &&
                setValue(
                  name,
                  R.reject(R.propEq("filename", file.filename), selectedFiles as any)
                )
              }
            />
          </Stack>
          {hasPreview && !R.isNil(imgData) && (
            <Stack>
              <img src={imgData as string} alt="selected" height="120" />
            </Stack>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

interface FileFieldType {
  name?: string;
  multiple?: boolean;
  disabled?: boolean;
  accept?: string;
  maxNumber?: number;
  selectedFiles?: FileType[];
  setValue?: UseFormSetValue<any>;
  serverFiles?: any[];
  onRemove?: (val: string) => Promise<void>;
  helperText?: string;
  simpleStyle?: boolean;
  notUpload?: boolean;
  downloadUrl?: string;
}

const InnerFileField = (
  {
    name = "",
    multiple = true,
    disabled = false,
    accept = "",
    maxNumber = MAX_NUMBER_OF_FILES,
    selectedFiles = [] as FileType[],
    setValue,
    serverFiles = [],
    onRemove,
    helperText,
    simpleStyle = false,
    notUpload = false,
    downloadUrl = "",
  }: FileFieldType,
  outerRef: ForwardedRef<HTMLInputElement>
) => {
  const hasPreview = !multiple && accept === ONLY_PNG_JPG;
  const savedLabel = <p style={{ margin: "0 10px 0 0" }}>저장 완료</p>;
  const innerRef = useRef<HTMLInputElement>(null);
  const baseRef = mergeRefs(outerRef, innerRef);

  const ServerFilesComp = (file: any) => {
    const file_path = encodeURIComponent(file.file_path as unknown as string);
    const file_name = R.replace(/%22/g, `"`, file.filename);
    const file_url = downloadUrl
      ? getFilePathWithPermission(downloadUrl, file_path)
      : getFilePath(file_path);
    return (
      <>
        <DownloadableLink href={file_url} fileName={file_name} withToken>
          {file_name}
        </DownloadableLink>
        <Box marginBottom={10} marginTop={0}>
          {file.owner_created_datetime ?? file.created_datetime} |{" "}
          {file.owner_created_by_slug ?? file.created_by_slug}
        </Box>

        {onRemove ? (
          <CloseButton
            className="file_close"
            onClick={() => onRemove(file.id)}
          />
        ) : null}
        {hasPreview && (
          <Stack>
            <img src={file_url} alt="server_file" height="120" />
          </Stack>
        )}
      </>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = R.map(
      (file) => ({ filename: file.name, file_path: file }),
      Array.from(e.target.files ?? [])
    );
    if (files.length + selectedFiles.length > maxNumber - serverFiles.length) {
      alert(`파일은 ${maxNumber}개까지 첨부 가능합니다.`);
      return;
    }
    setValue &&
      setValue(name, multiple ? R.concat(selectedFiles, files) : files);
    e.target.value = "";
  };

  const handleClick = () => {
    const fileInput = innerRef.current as HTMLInputElement;
    fileInput.click();
  };

  return (
    <Stack>
      {simpleStyle && (
        <Stack marginBottom={4}>
          <SelectedFiles
            simpleStyle={simpleStyle}
            setValue={setValue}
            name={name}
            selectedFiles={selectedFiles}
            hasPreview={hasPreview}
          />
          {serverFiles.length > 0 && savedLabel}
          {serverFiles.map((file) => (
            <Stack marginBottom={2} key={file.id}>
              <FilesWrapper className="xscroll">
                {ServerFilesComp(file)}
              </FilesWrapper>
            </Stack>
          ))}
        </Stack>
      )}
      {!simpleStyle && selectedFiles.length + serverFiles.length > 0 && (
        <FilesWrapper hasPreview={hasPreview}>
          <SelectedFiles
            simpleStyle={simpleStyle}
            setValue={setValue}
            name={name}
            selectedFiles={selectedFiles}
            hasPreview={hasPreview}
          />
          {serverFiles.map((file, idx) => (
            <Stack
              marginBottom={8}
              key={`${file.id}_${idx}`}
              alignItems="center"
            >
              {savedLabel}
              {ServerFilesComp(file)}
            </Stack>
          ))}
        </FilesWrapper>
      )}

      {!notUpload && (
        <UploadButtonBlock>
          <FileUploadButton
            disabled={disabled}
            onClick={handleClick}
            size="xsmall"
          >
            파일 업로드
          </FileUploadButton>
          <input
            type="file"
            name={name}
            disabled={disabled}
            accept={accept}
            multiple={multiple}
            onChange={(e) => handleChange(e)}
            ref={baseRef}
          />
          <p>{helperText}</p>
        </UploadButtonBlock>
      )}
    </Stack>
  );
};

export const FileField = forwardRef(InnerFileField);
