import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  getPathByMenuName,
  MenuListType,
} from '@/components/common-components/layouts/side-navigation-bar/menu-url-mapper';

const Navigation = ({
  show,
  menu,
  openedMenuState,
  selectedMenu,
  handleClickOnMenu,
}: NavigationProps): JSX.Element => {
  const opened = ({ name }: Pick<MenuListType, 'name'>) => openedMenuState.includes(name);
  const getEnabled = ({ enabled }: Pick<MenuListType, 'enabled'>) => enabled;
  const route = useRouter();

  return (
    <NavigationBlock show={show}>
      <NavigationBody>
        <ListBlock>
          {menu.filter(getEnabled).map((item) =>
            item?.sub?.length && item.sub.length > 0 ? (
              <ListItem key={item.name} active={opened(item)} isSub={true}>
                <span onClick={() => handleClickOnMenu(item.name)}>{item.name}</span>
                <SubListBlock>
                  {item.sub.filter(getEnabled).map(({ name, path }) => {
                    const pathByMenuName = getPathByMenuName(name);
                    const active = selectedMenu === name || route.pathname.includes(path);

                    if (!pathByMenuName)
                      throw new Error('NavigationBar: Could not find a path with a menu name.');

                    return (
                      <SubListItem key={name} active={active}>
                        <Link href={pathByMenuName}>{name}</Link>
                      </SubListItem>
                    );
                  })}
                </SubListBlock>
              </ListItem>
            ) : (
              <ListItem
                key={item.name}
                isSub={false}
                active={selectedMenu === item.name}
                isHasNoSub
              >
                <PageLink onClick={() => handleClickOnMenu(item.name)}>
                  <span>{item.name}</span>
                </PageLink>
              </ListItem>
            )
          )}
        </ListBlock>
      </NavigationBody>
    </NavigationBlock>
  );
};

export default Navigation;

const NavigationBlock = styled.div<Pick<NavigationProps, 'show'>>`
  position: fixed;
  z-index: 49;
  top: 63px;
  flex: 0 0 200px;
  height: 100%;
  background: #343b46;
  transition: margin 0.2s;
  margin-left: ${({ show }) => (show ? '0px' : '-200px')};
`;

const NavigationBody = styled.div`
  height: calc(100vh - 63px);
  overflow: auto;
`;

const ListBlock = styled.ul`
  span::selection,
  li::selection {
    background: transparent;
  }
`;

const SubListBlock = styled.ul``;

interface ListItemProps {
  active?: boolean;
  isSub?: boolean;
  isHasNoSub?: boolean;
}

const ListItem = styled.li<ListItemProps>`
  position: relative;
  min-height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #484e58;
  color: #fff;
  font-size: 19px;
  font-weight: bold;
  span {
    display: block;
    padding: 0 33px 0 20px;
    cursor: pointer;
  }

  ${({ active, isHasNoSub }) =>
    active &&
    isHasNoSub &&
    `
        color: #fff;
        background: #e1585e;
        &:before {
          border-color: #fff;
        }
      `}
`;

const SubListItem = styled.li<ListItemProps>`
  a {
    display: block;
    color: #c2c4c7;
    font-size: 14px;
    min-height: 30px;
    line-height: 30px;
    padding: 0 20px;
    &:hover {
      ${({ active }) => !active && 'background: #484e58'};
      text-decoration: none;
    }
    &:before {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border: solid 1px #757a81;
      border-top: 0;
      border-right: 0;
      margin-right: 10px;
      vertical-align: 0;
    }
    ${({ active }) =>
      active &&
      `
        color: #fff;
        background: #e1585e;
        &:before {
          border-color: #fff;
        }
      `}
  }
`;

const PageLink = styled.div`
  color: #fff;
`;

interface NavigationProps {
  show: boolean;
  menu: MenuListType[];
  openedMenuState: string[];
  selectedMenu: string;
  toggle: (menu: MenuListType[]) => void;
  open: (menu: MenuListType[]) => void;
  close: (menu: MenuListType[]) => void;
  handleClickOnMenu: (menu: string) => void;
}
