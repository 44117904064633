import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { PUBLIC_PATHS } from '@/constants/routePath';
import { useAppSelector } from '@/data/client-state/hooks/useStore';
import { initIdleTimer, stopIdleTimer } from '@/utils/hooks/idleSession/idleSession';

const emptyPathName = '';

export const useIdleSession = (): void => {
  const router = useRouter();
  const previousRoute = useAppSelector((state) => state.route.previousRoute);

  // this function check user switches between public page and secret page
  // if user goto public page then remove session timer
  // if user goto secret page then init new session timer
  function checkTimerWhenChangePage(prevPathName: string, currentPathName: string) {
    const previousPageIsPublic = PUBLIC_PATHS.includes(prevPathName);
    const previousPageIsSecret = !previousPageIsPublic;

    const currentPageIsPublic = PUBLIC_PATHS.includes(currentPathName);
    const currentPageIsSecret = !currentPageIsPublic;

    const isFirstPage = prevPathName === emptyPathName;
    if (previousPageIsSecret && currentPageIsPublic) {
      stopIdleTimer();
    } else if ((previousPageIsPublic || isFirstPage) && currentPageIsSecret) {
      initIdleTimer();
    }
  }

  useEffect(() => {
    checkTimerWhenChangePage(previousRoute, router.pathname);
  }, [previousRoute, router.pathname]);
};
