import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BlockDetailType } from '@/components/pages-components/block-management/block-detail/types';
import { BlockInformation } from '@/data/server-state/types/blockManagement';

import { RootState } from '../../store';

export type BlockListState = {
  blockList: BlockInformation[];
};

const initialState: BlockListState = {
  blockList: [],
};

export const blockListSlice = createSlice({
  name: 'blockListSlice',
  initialState,
  reducers: {
    updateBlockItem: (
      state,
      action: PayloadAction<{
        blockId: number;
        newBlock: BlockDetailType;
      }>
    ) => {
      const { blockId, newBlock } = action.payload;
      const index = state.blockList.findIndex((block) => block.blockId === blockId);
      if (index !== -1) {
        state.blockList[index] = {
          blockId: newBlock.block_id,
          type: newBlock.type,
          content: newBlock.content,
          reasonType: newBlock.reason_type,
          blocked: newBlock.blocked,
          createdAt: newBlock.created_at,
          updatedAt: newBlock.updated_at,
        };
      }
    },
    deleteBlockItem: (state, action: PayloadAction<number>) => {
      const blockId = action.payload;
      const index = state.blockList.findIndex((block) => block.blockId === blockId);
      if (index !== -1) {
        state.blockList.splice(index, 1);
      }
    },
    setBlockList: (state, action: PayloadAction<BlockInformation[]>) => {
      state.blockList = action.payload;
    },
  },
});

export const blockListReducer = blockListSlice.reducer;

export const { updateBlockItem, deleteBlockItem, setBlockList } = blockListSlice.actions;

export const selectBlockList = (state: RootState) => state.blockList.blockList;
