import {
  ButtonHTMLAttributes,
  CSSProperties,
  PropsWithChildren,
  useMemo
} from "react";
import { Close as CloseIcon } from "@mui/icons-material";
const ExcelIcon = require("../../assets/ico_excel.png");
import { ButtonProps, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

type StyleButtonProps = {
  primary?: boolean;
  outlined?: boolean;
  secondary?: boolean;
};

const StyleButton = styled.button<
  Pick<ButtonProps, "disabled"> & StyleButtonProps
>`
  display: inline-block;
  padding: 0;
  width: 100px;
  height: 24px;
  border: 1px solid #343b46;
  background: #fff;
  color: #343b46;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
  margin: 0 4px;
  ${({ primary }) =>
    primary &&
    css`
      background: #d61f26;
      border: unset;
      color: #fff;
    `}
  ${({ secondary }) =>
    secondary &&
    css`
      border: 1px solid #d9d9d9;
      background: #d9d9d9;
      color: #343b46;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background: #dcdcdc;
      border: unset;
      color: #fff;
    `}
  ${({ outlined }) =>
    outlined &&
    css`
      background: #ffffff;
      border: 1px solid #d01d22;
      color: #d01d22;
    `}
`;

type StyleSmallButtonProps = Pick<ButtonProps, "disabled"> &
  Pick<StyleButtonProps, "primary">;

const StyleSmallButton = styled.button<StyleSmallButtonProps>`
  display: inline-block;
  padding: 0 10px;
  height: 28px;
  background: ${({ primary, disabled }) =>
    primary ? "#d61f26" : disabled ? "#ccc" : "#343b46"};
  color: #fff;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
  flex: none;
  &:disabled {
    cursor: default;
  }
`;

const StyleMediumButton = styled.button<StyleSmallButtonProps>`
  display: inline-block;
  padding: 6px 8px;
  background: ${({ primary, disabled }) =>
    primary ? "#d61f26" : disabled ? "#ccc" : "#D9D9D9"};
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
  flex: none;
  min-width: 140px;
  &:disabled {
    cursor: default;
  }
`;
type StyleXsmallButtonProps = {
  secondary?: boolean;
  excel_download?: boolean;
};

const StyleXsmallButton = styled(StyleSmallButton)<
  Pick<StyleButtonProps, "primary"> & StyleXsmallButtonProps
>`
  height: 24px;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#343b46")};
  border-radius: 3px;
  font-weight: normal;
  ${({ primary }) =>
    primary &&
    css`
      background: #fff;
      border: 1px solid #343b46;
      color: #343b46;
    `}
  ${({ secondary }) =>
    secondary &&
    css`
      border: 1px solid #ccd3de;
      background: #e9eaeb;
      color: #343b46;
    `}
  ${({ excel_download }) =>
    excel_download
      ? css`
          background: #fff url(${ExcelIcon.src}) no-repeat 10px 4px;
          background-size: 12px auto;
          padding-left: 25px;
          border: 1px solid #19754c;
          color: #19754c;
        `
      : null}
`;

const IconStyle = css`
  width: 24px;
  height: 24px;
  padding: 0 0 3px;
  border: 1px solid #ccd3de;
  border-radius: 3px;
  background: #e9eaeb;
  color: #343b46;
  font-size: 16px;
  font-weight: bold;
`;

const StyleAddButton = styled.button`
  ${IconStyle}
  color: #2e86e6;
`;

const StyleRemoveButton = styled.button`
  ${IconStyle}
  color: #e1575d;
`;

type ButtonBaseProps = StyleButtonProps &
  StyleXsmallButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    size?: "small" | "xsmall" | "medium";
    style?: CSSProperties;
    onClick?: () => void;
    loading?: boolean;
  }>;

const Button = ({ ...props }: ButtonBaseProps): JSX.Element => {
  const { size = "medium", loading, children } = props;

  const BtnComponent = useMemo(() => {
    switch (size) {
      case "small":
        return SmallButton;
      case "xsmall":
        return XsmallButton;
      default:
        return StyleButton;
    }
  }, [size]);

  const handlerClick = () => {
    if (!loading) {
      props?.onClick?.();
    }
  };

  return (
    <BtnComponent {...props} onClick={handlerClick}>
      {loading ? (
        <CircularProgress
          size={20}
          color="inherit"
          css={{ maxHeight: "100%" }}
        />
      ) : (
        children
      )}
    </BtnComponent>
  );
};

const SmallButton = ({ children, ...props }: ButtonBaseProps): JSX.Element => (
  <StyleSmallButton {...props}>{children}</StyleSmallButton>
);

const XsmallButton = ({ children, ...props }: ButtonBaseProps): JSX.Element => (
  <StyleXsmallButton {...props}>{children}</StyleXsmallButton>
);

const MediumButton = ({ children, ...props }: ButtonBaseProps): JSX.Element => (
  <StyleMediumButton {...props}>{children}</StyleMediumButton>
);

type FunctionalButtonProps = Omit<ButtonBaseProps, "children" | "size">;

const AddButton = ({ ...props }: FunctionalButtonProps): JSX.Element => (
  <StyleAddButton {...props}>+</StyleAddButton>
);

const RemoveButton = ({ ...props }: FunctionalButtonProps): JSX.Element => (
  <StyleRemoveButton {...props}>-</StyleRemoveButton>
);

const commonBtnStyles = css`
  font-size: 1rem;
  padding: 0.8rem 1rem;
  height: 38px;
  width: max-content;
  min-width: 8rem;
  border: none;
`;

const ExitButton = styled(Button)`
  ${commonBtnStyles}
  border: 1px solid #000000;
  color: #161616;
  background: none;
  margin: 0 auto;
  display: block;
`;

const SubmitButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.red[90]};
  `};
  ${commonBtnStyles}
`;

const ExportButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
  `};
  ${commonBtnStyles}
`;

const SaveButton = styled(Button)`
  border: 1px solid #ccd3de;
  background: #e9eaeb;
  color: #343b46;
  ${commonBtnStyles}
`;

const CloseButton = styled(CloseIcon)`
  &.shortcut_close {
    font-size: 13px;
  }
  &.popup_close {
    font-size: 30px;
  }
  &.file_close {
    font-size: 15px;
    vertical-align: middle;
  }
  cursor: pointer;
  color: #b3b3b3;
  path {
    pointer-events: none;
  }
`;

const ButtonBlock = styled.div`
  padding-top: 12px;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const ButtonGroupLeft = styled.div`
  display: flex;
  > div,
  button {
    margin-right: 4px;
  }
`;

const ButtonGroupRight = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  button {
    margin-left: 8px;
  }
`;

export {
  Button,
  ButtonBlock,
  ButtonGroup,
  ButtonGroupLeft,
  ButtonGroupRight,
  CloseButton,
  AddButton,
  RemoveButton,
  SubmitButton,
  ExportButton,
  MediumButton,
  SaveButton,
  ExitButton,
};
