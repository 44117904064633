import { useTheme } from "@emotion/react";
import { Stack } from "@mui/material";
import React, {
  ComponentPropsWithoutRef,
  useRef,
  forwardRef,
  ForwardedRef,
} from "react";
import mergeRefs from "../../utils/mergeRefs";

type StripedGridItemProps = {
  title: string;
  children: React.ReactNode;
} & ComponentPropsWithoutRef<"div">;

export const StripedGridItem = forwardRef(
  (
    { title, content, children, ...props }: StripedGridItemProps,
    outerRef: ForwardedRef<HTMLDivElement>
  ) => {
    const theme = useTheme();
    const innerRef = useRef<HTMLInputElement>(null);
    const baseRef = mergeRefs(outerRef, innerRef);

    return (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          borderBottom: `2px solid ${theme.colors.gray[10]}`,
          minHeight: "40px",
          height: "100%",
        }}
      >
        <Stack
          data-cy="title-grid-item"
          direction="row"
          alignItems={"center"}
          sx={{
            minWidth: "10rem",
            maxWidth: "10rem",
            height: "100%",
            backgroundColor: "#f5f5f5",
            fontSize: "1rem",
            paddingLeft: "1rem",
            fontWeight: "bold",
          }}
        >
          {title}
        </Stack>
        <div
          ref={baseRef}
          style={{
            paddingLeft: "1rem",
            paddingRight: "1rem",
            flex: 1,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            height: "100%",
            overflow: "hidden",
          }}
          {...props}
        >
          {children}
        </div>
      </Stack>
    );
  }
);
