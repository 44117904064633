import styled from "@emotion/styled";
import { CSSProperties, ChangeEvent, KeyboardEvent, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { css } from "@emotion/react";

export type ChangePasswordField =
  | "currentPassword"
  | "newPassword"
  | "confirmNewPassword";

interface TextFieldUserPasswordProps {
  register: UserPasswordFormRegister;
  passwordInput: string;
  error?: string;
  placeholder?: string;
  fieldRegister: ChangePasswordField | "userPassword";
  customCss?: CSSProperties;
  handleEnter?: () => void;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}
interface PasswordOptionType {
  type: "password" | "text";
  text: "보기" | "숨기기";
}

export const TextFieldUserPassword = ({
  passwordInput,
  register,
  error,
  placeholder = "비밀번호",
  fieldRegister,
  customCss,
  handleEnter,
  handleChange,
  handleBlur,
}: TextFieldUserPasswordProps) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleEnter?.();
    }
  };

  const [passwordToggle, setPasswordOption] = useState<PasswordOptionType>({
    type: "password",
    text: "보기",
  });

  const handlePasswordToggleToggle = () => {
    setPasswordOption(({ type }) =>
      type === "password"
        ? { type: "text", text: "숨기기" }
        : { type: "password", text: "보기" }
    );
  };

  return (
    <div
      style={{
        position: "relative",
        width: customCss?.width ? `${customCss?.width}px` : "100%",
      }}
    >
      <TextField
        id={fieldRegister}
        type={passwordToggle.type}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        maxLength={30}
        {...register(fieldRegister, {
          onChange: handleChange,
          onBlur: handleBlur,
        })}
        error={error}
        width="100%"
        height={customCss?.height}
        customCss={{
          background: customCss?.background,
          border: customCss?.border,
        }}
        css={
          customCss?.position &&
          css`
            div {
              position: ${customCss?.position};
            }
          `
        }
      />
      <PasswordToggleButton
        type="button"
        show={Boolean(passwordInput)}
        onClick={handlePasswordToggleToggle}
      >
        {passwordToggle.text}
      </PasswordToggleButton>
    </div>
  );
};

const ControlButton = styled.button<{ show: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 25px;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #808080;
  display: ${({ show }) => !show && "none"};
`;

const PasswordToggleButton = styled(ControlButton)`
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #808080;
`;

type UserPasswordFormRegister = UseFormRegister<
  | {
      userPassword: string;
    }
  | {
      currentPassword: string;
    }
  | {
      newPassword: string;
    }
  | {
      confirmNewPassword: string;
    }
>;
