import { SerializedStyles } from '@emotion/react';
import {
  createCSSWithExactMediaQuery,
  createCSSWithGreatThenMediaQuery,
  createCSSWithLessThenMediaQuery,
} from './create-media-query';

/**
 * We categorize devices by their sizes like the below
 * For more details, visit https://github.com/angular/flex-layout/wiki/Responsive-API
 *                                                                                      +-- gt.lg -- +
 *              +--------------------------- gt.xs --------------------------------------------------+ `gt` means "great then"
 * +-------------- lt.md ---------------+
 * +-- lt.sm ---+                                                                                      `lt` means "less then"
 * +---- xs ----+--------- sm ----------+--------- md ----------+--------- lg ----------+---- xl ----+  It is called `exact`
 * +-  ~360px  -+-   360px ~ 767px     -+-   768px ~ 1023px    -+-   1024px ~ 1279px   -+  ~1280px   +
 * |------------|-----------------------|-----------------------|-----------------------|------------|
 * 0          360px                    768px                  1024px                  1280px
 */

export const DEFAULT_DEVICE_SIZES = {
  mobile: 360,
  tablet: 768,
  pos: 1024,
  desktop: 1280,
} as const;

export type Devices = typeof DEFAULT_DEVICE_SIZES;

type CreateBreakPoint = (style: SerializedStyles) => SerializedStyles;

type BaseBreakPoints = {
  xs: CreateBreakPoint;
  sm: CreateBreakPoint;
  md: CreateBreakPoint;
  lg: CreateBreakPoint;
  xl: CreateBreakPoint;
};

export type Breakpoints = BaseBreakPoints & {
  gt: Omit<BaseBreakPoints, 'xl'>;
  lt: Omit<BaseBreakPoints, 'xs'>;
};

export const createBreakpoints = (devices: Devices = DEFAULT_DEVICE_SIZES): Breakpoints => {
  const exact = {
    xs: createCSSWithExactMediaQuery(undefined, devices.mobile),
    sm: createCSSWithExactMediaQuery(devices.mobile, devices.tablet),
    md: createCSSWithExactMediaQuery(devices.tablet, devices.pos),
    lg: createCSSWithExactMediaQuery(devices.pos, devices.desktop),
    xl: createCSSWithExactMediaQuery(devices.desktop),
  };

  const gt = {
    xs: createCSSWithGreatThenMediaQuery(devices.mobile),
    sm: createCSSWithGreatThenMediaQuery(devices.tablet),
    md: createCSSWithGreatThenMediaQuery(devices.pos),
    lg: createCSSWithGreatThenMediaQuery(devices.desktop),
  };

  const lt = {
    sm: createCSSWithLessThenMediaQuery(devices.mobile),
    md: createCSSWithLessThenMediaQuery(devices.tablet),
    lg: createCSSWithLessThenMediaQuery(devices.pos),
    xl: createCSSWithLessThenMediaQuery(devices.desktop),
  };

  return { ...exact, gt, lt };
};
